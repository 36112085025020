/* Colors for the ripple elements.*/
/* stylelint-disable-next-line material/theme-mixin-api */
/* stylelint-disable-next-line material/theme-mixin-api */
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1, .mat-headline, .mat-typography h1 {
  font: 400 24px/32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2, .mat-title, .mat-typography h2 {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3, .mat-subheading-2, .mat-typography h3 {
  font: 400 16px/28px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4, .mat-subheading-1, .mat-typography h4 {
  font: 400 15px/24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5, .mat-typography h5 {
  font: 400 calc(14px * 0.83)/20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-h6, .mat-typography h6 {
  font: 400 calc(14px * 0.67)/20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong, .mat-body-2 {
  font: 500 14px/24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-body, .mat-body-1, .mat-typography {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-body p, .mat-body-1 p, .mat-typography p {
  margin: 0 0 12px;
}

.mat-small, .mat-caption {
  font: 400 12px/20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-display-4, .mat-typography .mat-display-4 {
  font: 300 112px/112px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}

.mat-display-3, .mat-typography .mat-display-3 {
  font: 400 56px/56px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-display-2, .mat-typography .mat-display-2 {
  font: 400 45px/48px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-display-1, .mat-typography .mat-display-1 {
  font: 400 34px/40px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  -ms-transform: translateY(-1.28125em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  -ms-transform: translateY(-1.28124em) scale(0.75);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  -ms-transform: translateY(-1.28123em) scale(0.75);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}

.mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tab-label, .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}

.mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}

.mat-optgroup-label {
  font: 500 14px/24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
@media screen and (-ms-high-contrast: active) {
  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 0.6;
  }
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop, .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

@keyframes cdk-text-field-autofill-start {
  /*!*/
}
@keyframes cdk-text-field-autofill-end {
  /*!*/
}
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

.mat-focus-indicator {
  position: relative;
}

.mat-mdc-focus-indicator {
  position: relative;
}

.title-margin {
  margin: 10px;
  font-size: 20px;
}

.mat-tab-label {
  font-family: "Muli", "Helvetica Neue", Helvetica, sans-serif;
  white-space: nowrap;
}

.app-light .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.app-light .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.app-light .mat-option:hover:not(.mat-option-disabled), .app-light .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.app-light .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.app-light .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.app-light .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.app-light .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #007dbd;
}
.app-light .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ffd740;
}
.app-light .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336;
}
.app-light .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.app-light .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.app-light .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.app-light .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.app-light .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.app-light .mat-primary .mat-pseudo-checkbox-checked,
.app-light .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #007dbd;
}
.app-light .mat-pseudo-checkbox-checked,
.app-light .mat-pseudo-checkbox-indeterminate,
.app-light .mat-accent .mat-pseudo-checkbox-checked,
.app-light .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #ffd740;
}
.app-light .mat-warn .mat-pseudo-checkbox-checked,
.app-light .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f44336;
}
.app-light .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.app-light .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.app-light .mat-app-background, .app-light.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.app-light .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app-light .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app-light .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app-light .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app-light .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app-light .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.app-light .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app-light .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.app-light .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app-light .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.app-light .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.app-light .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.app-light .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app-light .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.app-light .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.app-light .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.app-light .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app-light .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.app-light .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.app-light .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.app-light .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.app-light .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.app-light .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.app-light .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.app-light .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.app-light .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app-light .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app-light .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.app-light .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.app-light .mat-badge-content {
  color: white;
  background: #007dbd;
}
.cdk-high-contrast-active .app-light .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.app-light .mat-badge-accent .mat-badge-content {
  background: #ffd740;
  color: rgba(0, 0, 0, 0.87);
}
.app-light .mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}
.app-light .mat-badge {
  position: relative;
}
.app-light .mat-badge-hidden .mat-badge-content {
  display: none;
}
.app-light .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.app-light .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.app-light .ng-animate-disabled .mat-badge-content,
.app-light .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.app-light .mat-badge-content.mat-badge-active {
  transform: none;
}
.app-light .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.app-light .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.app-light .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.app-light .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .app-light .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.app-light .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .app-light .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.app-light .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .app-light .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.app-light .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .app-light .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.app-light .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.app-light .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.app-light .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.app-light .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .app-light .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.app-light .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .app-light .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.app-light .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .app-light .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.app-light .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .app-light .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.app-light .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.app-light .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.app-light .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.app-light .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .app-light .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.app-light .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .app-light .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.app-light .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .app-light .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.app-light .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .app-light .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.app-light .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app-light .mat-button, .app-light .mat-icon-button, .app-light .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.app-light .mat-button.mat-primary, .app-light .mat-icon-button.mat-primary, .app-light .mat-stroked-button.mat-primary {
  color: #007dbd;
}
.app-light .mat-button.mat-accent, .app-light .mat-icon-button.mat-accent, .app-light .mat-stroked-button.mat-accent {
  color: #ffd740;
}
.app-light .mat-button.mat-warn, .app-light .mat-icon-button.mat-warn, .app-light .mat-stroked-button.mat-warn {
  color: #f44336;
}
.app-light .mat-button.mat-primary.mat-button-disabled, .app-light .mat-button.mat-accent.mat-button-disabled, .app-light .mat-button.mat-warn.mat-button-disabled, .app-light .mat-button.mat-button-disabled.mat-button-disabled, .app-light .mat-icon-button.mat-primary.mat-button-disabled, .app-light .mat-icon-button.mat-accent.mat-button-disabled, .app-light .mat-icon-button.mat-warn.mat-button-disabled, .app-light .mat-icon-button.mat-button-disabled.mat-button-disabled, .app-light .mat-stroked-button.mat-primary.mat-button-disabled, .app-light .mat-stroked-button.mat-accent.mat-button-disabled, .app-light .mat-stroked-button.mat-warn.mat-button-disabled, .app-light .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.app-light .mat-button.mat-primary .mat-button-focus-overlay, .app-light .mat-icon-button.mat-primary .mat-button-focus-overlay, .app-light .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #007dbd;
}
.app-light .mat-button.mat-accent .mat-button-focus-overlay, .app-light .mat-icon-button.mat-accent .mat-button-focus-overlay, .app-light .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #ffd740;
}
.app-light .mat-button.mat-warn .mat-button-focus-overlay, .app-light .mat-icon-button.mat-warn .mat-button-focus-overlay, .app-light .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #f44336;
}
.app-light .mat-button.mat-button-disabled .mat-button-focus-overlay, .app-light .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .app-light .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.app-light .mat-button .mat-ripple-element, .app-light .mat-icon-button .mat-ripple-element, .app-light .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.app-light .mat-button-focus-overlay {
  background: black;
}
.app-light .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.app-light .mat-flat-button, .app-light .mat-raised-button, .app-light .mat-fab, .app-light .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.app-light .mat-flat-button.mat-primary, .app-light .mat-raised-button.mat-primary, .app-light .mat-fab.mat-primary, .app-light .mat-mini-fab.mat-primary {
  color: white;
}
.app-light .mat-flat-button.mat-accent, .app-light .mat-raised-button.mat-accent, .app-light .mat-fab.mat-accent, .app-light .mat-mini-fab.mat-accent {
  color: rgba(0, 0, 0, 0.87);
}
.app-light .mat-flat-button.mat-warn, .app-light .mat-raised-button.mat-warn, .app-light .mat-fab.mat-warn, .app-light .mat-mini-fab.mat-warn {
  color: white;
}
.app-light .mat-flat-button.mat-primary.mat-button-disabled, .app-light .mat-flat-button.mat-accent.mat-button-disabled, .app-light .mat-flat-button.mat-warn.mat-button-disabled, .app-light .mat-flat-button.mat-button-disabled.mat-button-disabled, .app-light .mat-raised-button.mat-primary.mat-button-disabled, .app-light .mat-raised-button.mat-accent.mat-button-disabled, .app-light .mat-raised-button.mat-warn.mat-button-disabled, .app-light .mat-raised-button.mat-button-disabled.mat-button-disabled, .app-light .mat-fab.mat-primary.mat-button-disabled, .app-light .mat-fab.mat-accent.mat-button-disabled, .app-light .mat-fab.mat-warn.mat-button-disabled, .app-light .mat-fab.mat-button-disabled.mat-button-disabled, .app-light .mat-mini-fab.mat-primary.mat-button-disabled, .app-light .mat-mini-fab.mat-accent.mat-button-disabled, .app-light .mat-mini-fab.mat-warn.mat-button-disabled, .app-light .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.app-light .mat-flat-button.mat-primary, .app-light .mat-raised-button.mat-primary, .app-light .mat-fab.mat-primary, .app-light .mat-mini-fab.mat-primary {
  background-color: #007dbd;
}
.app-light .mat-flat-button.mat-accent, .app-light .mat-raised-button.mat-accent, .app-light .mat-fab.mat-accent, .app-light .mat-mini-fab.mat-accent {
  background-color: #ffd740;
}
.app-light .mat-flat-button.mat-warn, .app-light .mat-raised-button.mat-warn, .app-light .mat-fab.mat-warn, .app-light .mat-mini-fab.mat-warn {
  background-color: #f44336;
}
.app-light .mat-flat-button.mat-primary.mat-button-disabled, .app-light .mat-flat-button.mat-accent.mat-button-disabled, .app-light .mat-flat-button.mat-warn.mat-button-disabled, .app-light .mat-flat-button.mat-button-disabled.mat-button-disabled, .app-light .mat-raised-button.mat-primary.mat-button-disabled, .app-light .mat-raised-button.mat-accent.mat-button-disabled, .app-light .mat-raised-button.mat-warn.mat-button-disabled, .app-light .mat-raised-button.mat-button-disabled.mat-button-disabled, .app-light .mat-fab.mat-primary.mat-button-disabled, .app-light .mat-fab.mat-accent.mat-button-disabled, .app-light .mat-fab.mat-warn.mat-button-disabled, .app-light .mat-fab.mat-button-disabled.mat-button-disabled, .app-light .mat-mini-fab.mat-primary.mat-button-disabled, .app-light .mat-mini-fab.mat-accent.mat-button-disabled, .app-light .mat-mini-fab.mat-warn.mat-button-disabled, .app-light .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.app-light .mat-flat-button.mat-primary .mat-ripple-element, .app-light .mat-raised-button.mat-primary .mat-ripple-element, .app-light .mat-fab.mat-primary .mat-ripple-element, .app-light .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app-light .mat-flat-button.mat-accent .mat-ripple-element, .app-light .mat-raised-button.mat-accent .mat-ripple-element, .app-light .mat-fab.mat-accent .mat-ripple-element, .app-light .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.app-light .mat-flat-button.mat-warn .mat-ripple-element, .app-light .mat-raised-button.mat-warn .mat-ripple-element, .app-light .mat-fab.mat-warn .mat-ripple-element, .app-light .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app-light .mat-stroked-button:not([class*=mat-elevation-z]), .app-light .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app-light .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app-light .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app-light .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app-light .mat-fab:not([class*=mat-elevation-z]), .app-light .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app-light .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .app-light .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app-light .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .app-light .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app-light .mat-button-toggle-standalone,
.app-light .mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app-light .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.app-light .mat-button-toggle-group-appearance-standard {
  box-shadow: none;
}
.app-light .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.app-light .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.app-light .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.app-light .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.app-light .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
}
.app-light [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.app-light .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px rgba(0, 0, 0, 0.12);
}
.app-light .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.app-light .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.app-light .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.app-light .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.app-light .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.app-light .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.app-light .mat-button-toggle-group-appearance-standard {
  border: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.app-light .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app-light .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app-light .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app-light .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.app-light .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.app-light .mat-checkbox-checkmark {
  fill: #fafafa;
}
.app-light .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.app-light .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.app-light .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .app-light .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #007dbd;
}
.app-light .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .app-light .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #ffd740;
}
.app-light .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .app-light .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f44336;
}
.app-light .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .app-light .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.app-light .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.app-light .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.54);
}
.app-light .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.app-light .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.app-light .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #007dbd;
}
.app-light .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.app-light .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #ffd740;
}
.app-light .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.app-light .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #f44336;
}
.app-light .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.app-light .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.app-light .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app-light .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.app-light .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.app-light .mat-chip.mat-standard-chip::after {
  background: black;
}
.app-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #007dbd;
  color: white;
}
.app-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f44336;
  color: white;
}
.app-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #ffd740;
  color: rgba(0, 0, 0, 0.87);
}
.app-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.app-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.app-light .mat-table {
  background: white;
}
.app-light .mat-table thead, .app-light .mat-table tbody, .app-light .mat-table tfoot,
.app-light mat-header-row, .app-light mat-row, .app-light mat-footer-row,
.app-light [mat-header-row], .app-light [mat-row], .app-light [mat-footer-row],
.app-light .mat-table-sticky {
  background: inherit;
}
.app-light mat-row, .app-light mat-header-row, .app-light mat-footer-row,
.app-light th.mat-header-cell, .app-light td.mat-cell, .app-light td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.app-light .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.app-light .mat-cell, .app-light .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.app-light .mat-calendar-arrow {
  border-top-color: rgba(0, 0, 0, 0.54);
}
.app-light .mat-datepicker-toggle,
.app-light .mat-datepicker-content .mat-calendar-next-button,
.app-light .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.app-light .mat-calendar-table-header {
  color: rgba(0, 0, 0, 0.38);
}
.app-light .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.app-light .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.app-light .mat-calendar-body-cell-content,
.app-light .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.app-light .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.app-light .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.app-light .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.app-light .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.app-light .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  background-color: rgba(0, 0, 0, 0.04);
}
.app-light .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.app-light .mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: rgba(0, 0, 0, 0.38);
}
.app-light .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: rgba(0, 0, 0, 0.18);
}
.app-light .mat-calendar-body-in-range::before {
  background: rgba(0, 125, 189, 0.2);
}
.app-light .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app-light .mat-calendar-body-comparison-bridge-start::before,
.app-light [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(0, 125, 189, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app-light .mat-calendar-body-comparison-bridge-end::before,
.app-light [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(0, 125, 189, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app-light .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app-light .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app-light .mat-calendar-body-selected {
  background-color: #007dbd;
  color: white;
}
.app-light .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(0, 125, 189, 0.4);
}
.app-light .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app-light .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.app-light .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(255, 215, 64, 0.2);
}
.app-light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app-light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.app-light .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 215, 64, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app-light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.app-light .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 215, 64, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app-light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app-light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app-light .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #ffd740;
  color: rgba(0, 0, 0, 0.87);
}
.app-light .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 215, 64, 0.4);
}
.app-light .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.app-light .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.app-light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app-light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.app-light .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app-light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.app-light .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app-light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app-light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app-light .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.app-light .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.app-light .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app-light .mat-datepicker-content-touch {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app-light .mat-datepicker-toggle-active {
  color: #007dbd;
}
.app-light .mat-datepicker-toggle-active.mat-accent {
  color: #ffd740;
}
.app-light .mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}
.app-light .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.app-light .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app-light .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app-light .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.app-light .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app-light .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app-light .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app-light .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .app-light .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .app-light .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .app-light .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.app-light .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.app-light .mat-expansion-panel-header-description,
.app-light .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.app-light .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.app-light .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.app-light .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.app-light .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.app-light .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.app-light .mat-form-field.mat-focused .mat-form-field-label {
  color: #007dbd;
}
.app-light .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #ffd740;
}
.app-light .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #f44336;
}
.app-light .mat-focused .mat-form-field-required-marker {
  color: #ffd740;
}
.app-light .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.app-light .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #007dbd;
}
.app-light .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #ffd740;
}
.app-light .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #f44336;
}
.app-light .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #007dbd;
}
.app-light .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #ffd740;
}
.app-light .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #f44336;
}
.app-light .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336;
}
.app-light .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.app-light .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #f44336;
}
.app-light .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.app-light .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f44336;
}
.app-light .mat-error {
  color: #f44336;
}
.app-light .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.app-light .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.app-light .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.app-light .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.app-light .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.app-light .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.app-light .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.app-light .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.app-light .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.app-light .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.app-light .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.app-light .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.app-light .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.app-light .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #007dbd;
}
.app-light .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #ffd740;
}
.app-light .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #f44336;
}
.app-light .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336;
}
.app-light .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.app-light .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.app-light .mat-icon.mat-primary {
  color: #007dbd;
}
.app-light .mat-icon.mat-accent {
  color: #ffd740;
}
.app-light .mat-icon.mat-warn {
  color: #f44336;
}
.app-light .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.app-light .mat-input-element:disabled,
.app-light .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.app-light .mat-input-element {
  caret-color: #007dbd;
}
.app-light .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app-light .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app-light .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app-light .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app-light .mat-form-field.mat-accent .mat-input-element {
  caret-color: #ffd740;
}
.app-light .mat-form-field.mat-warn .mat-input-element,
.app-light .mat-form-field-invalid .mat-input-element {
  caret-color: #f44336;
}
.app-light .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #f44336;
}
.app-light .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.app-light .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.app-light .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.app-light .mat-list-item-disabled {
  background-color: #eeeeee;
}
.app-light .mat-list-option:hover, .app-light .mat-list-option:focus,
.app-light .mat-nav-list .mat-list-item:hover,
.app-light .mat-nav-list .mat-list-item:focus,
.app-light .mat-action-list .mat-list-item:hover,
.app-light .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.app-light .mat-list-single-selected-option, .app-light .mat-list-single-selected-option:hover, .app-light .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.app-light .mat-menu-panel {
  background: white;
}
.app-light .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app-light .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.app-light .mat-menu-item[disabled], .app-light .mat-menu-item[disabled]::after {
  color: rgba(0, 0, 0, 0.38);
}
.app-light .mat-menu-item .mat-icon-no-color,
.app-light .mat-menu-item-submenu-trigger::after {
  color: rgba(0, 0, 0, 0.54);
}
.app-light .mat-menu-item:hover:not([disabled]),
.app-light .mat-menu-item.cdk-program-focused:not([disabled]),
.app-light .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.app-light .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.app-light .mat-paginator {
  background: white;
}
.app-light .mat-paginator,
.app-light .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.app-light .mat-paginator-decrement,
.app-light .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.app-light .mat-paginator-first,
.app-light .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.app-light .mat-icon-button[disabled] .mat-paginator-decrement,
.app-light .mat-icon-button[disabled] .mat-paginator-increment,
.app-light .mat-icon-button[disabled] .mat-paginator-first,
.app-light .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-paginator-container {
  min-height: 56px;
}

.app-light .mat-progress-bar-background {
  fill: #b3d8eb;
}
.app-light .mat-progress-bar-buffer {
  background-color: #b3d8eb;
}
.app-light .mat-progress-bar-fill::after {
  background-color: #007dbd;
}
.app-light .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #ffe57f;
}
.app-light .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #ffe57f;
}
.app-light .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #ffd740;
}
.app-light .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #ffcdd2;
}
.app-light .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #ffcdd2;
}
.app-light .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f44336;
}
.app-light .mat-progress-spinner circle, .app-light .mat-spinner circle {
  stroke: #007dbd;
}
.app-light .mat-progress-spinner.mat-accent circle, .app-light .mat-spinner.mat-accent circle {
  stroke: #ffd740;
}
.app-light .mat-progress-spinner.mat-warn circle, .app-light .mat-spinner.mat-warn circle {
  stroke: #f44336;
}
.app-light .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.app-light .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #007dbd;
}
.app-light .mat-radio-button.mat-primary .mat-radio-inner-circle,
.app-light .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app-light .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .app-light .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #007dbd;
}
.app-light .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ffd740;
}
.app-light .mat-radio-button.mat-accent .mat-radio-inner-circle,
.app-light .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app-light .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .app-light .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #ffd740;
}
.app-light .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336;
}
.app-light .mat-radio-button.mat-warn .mat-radio-inner-circle,
.app-light .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app-light .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .app-light .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #f44336;
}
.app-light .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.app-light .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.app-light .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.app-light .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.app-light .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.app-light .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.app-light .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.app-light .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app-light .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.app-light .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.app-light .mat-select-panel {
  background: white;
}
.app-light .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app-light .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.app-light .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #007dbd;
}
.app-light .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #ffd740;
}
.app-light .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f44336;
}
.app-light .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f44336;
}
.app-light .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.app-light .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.app-light .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.app-light .mat-drawer.mat-drawer-push {
  background-color: white;
}
.app-light .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app-light .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.app-light .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.app-light [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.app-light [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.app-light .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.app-light .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #ffd740;
}
.app-light .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 215, 64, 0.54);
}
.app-light .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #ffd740;
}
.app-light .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #007dbd;
}
.app-light .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(0, 125, 189, 0.54);
}
.app-light .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #007dbd;
}
.app-light .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #f44336;
}
.app-light .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 67, 54, 0.54);
}
.app-light .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #f44336;
}
.app-light .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.app-light .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.app-light .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.app-light .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.app-light .mat-primary .mat-slider-track-fill,
.app-light .mat-primary .mat-slider-thumb,
.app-light .mat-primary .mat-slider-thumb-label {
  background-color: #007dbd;
}
.app-light .mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.app-light .mat-primary .mat-slider-focus-ring {
  background-color: rgba(0, 125, 189, 0.2);
}
.app-light .mat-accent .mat-slider-track-fill,
.app-light .mat-accent .mat-slider-thumb,
.app-light .mat-accent .mat-slider-thumb-label {
  background-color: #ffd740;
}
.app-light .mat-accent .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.app-light .mat-accent .mat-slider-focus-ring {
  background-color: rgba(255, 215, 64, 0.2);
}
.app-light .mat-warn .mat-slider-track-fill,
.app-light .mat-warn .mat-slider-thumb,
.app-light .mat-warn .mat-slider-thumb-label {
  background-color: #f44336;
}
.app-light .mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.app-light .mat-warn .mat-slider-focus-ring {
  background-color: rgba(244, 67, 54, 0.2);
}
.app-light .mat-slider:hover .mat-slider-track-background,
.app-light .cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.app-light .mat-slider-disabled .mat-slider-track-background,
.app-light .mat-slider-disabled .mat-slider-track-fill,
.app-light .mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.app-light .mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.app-light .mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.app-light .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.app-light .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.app-light .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.app-light .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.app-light .mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.app-light .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .app-light .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.app-light .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .app-light .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.app-light .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.app-light .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.app-light .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.app-light .mat-step-header.cdk-keyboard-focused, .app-light .mat-step-header.cdk-program-focused, .app-light .mat-step-header:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .app-light .mat-step-header:hover {
    background: none;
  }
}
.app-light .mat-step-header .mat-step-label,
.app-light .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.app-light .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.app-light .mat-step-header .mat-step-icon-selected,
.app-light .mat-step-header .mat-step-icon-state-done,
.app-light .mat-step-header .mat-step-icon-state-edit {
  background-color: #007dbd;
  color: white;
}
.app-light .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.app-light .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.app-light .mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}
.app-light .mat-stepper-horizontal, .app-light .mat-stepper-vertical {
  background-color: white;
}
.app-light .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.app-light .mat-horizontal-stepper-header::before,
.app-light .mat-horizontal-stepper-header::after,
.app-light .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}

.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.app-light .mat-sort-header-arrow {
  color: #757575;
}
.app-light .mat-tab-nav-bar,
.app-light .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.app-light .mat-tab-group-inverted-header .mat-tab-nav-bar,
.app-light .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.app-light .mat-tab-label, .app-light .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.app-light .mat-tab-label.mat-tab-disabled, .app-light .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.app-light .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.app-light .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.app-light .mat-tab-group[class*=mat-background-] .mat-tab-header,
.app-light .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.app-light .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app-light .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app-light .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app-light .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app-light .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app-light .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app-light .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app-light .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(179, 216, 235, 0.3);
}
.app-light .mat-tab-group.mat-primary .mat-ink-bar, .app-light .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #007dbd;
}
.app-light .mat-tab-group.mat-primary.mat-background-primary .mat-ink-bar, .app-light .mat-tab-nav-bar.mat-primary.mat-background-primary .mat-ink-bar {
  background-color: white;
}
.app-light .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app-light .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app-light .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app-light .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app-light .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app-light .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app-light .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app-light .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 229, 127, 0.3);
}
.app-light .mat-tab-group.mat-accent .mat-ink-bar, .app-light .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #ffd740;
}
.app-light .mat-tab-group.mat-accent.mat-background-accent .mat-ink-bar, .app-light .mat-tab-nav-bar.mat-accent.mat-background-accent .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.app-light .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app-light .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app-light .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app-light .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app-light .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app-light .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app-light .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app-light .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.app-light .mat-tab-group.mat-warn .mat-ink-bar, .app-light .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f44336;
}
.app-light .mat-tab-group.mat-warn.mat-background-warn .mat-ink-bar, .app-light .mat-tab-nav-bar.mat-warn.mat-background-warn .mat-ink-bar {
  background-color: white;
}
.app-light .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app-light .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app-light .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app-light .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app-light .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app-light .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app-light .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app-light .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(179, 216, 235, 0.3);
}
.app-light .mat-tab-group.mat-background-primary .mat-tab-header, .app-light .mat-tab-group.mat-background-primary .mat-tab-links, .app-light .mat-tab-group.mat-background-primary .mat-tab-header-pagination, .app-light .mat-tab-nav-bar.mat-background-primary .mat-tab-header, .app-light .mat-tab-nav-bar.mat-background-primary .mat-tab-links, .app-light .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination {
  background-color: #007dbd;
}
.app-light .mat-tab-group.mat-background-primary .mat-tab-label, .app-light .mat-tab-group.mat-background-primary .mat-tab-link, .app-light .mat-tab-nav-bar.mat-background-primary .mat-tab-label, .app-light .mat-tab-nav-bar.mat-background-primary .mat-tab-link {
  color: white;
}
.app-light .mat-tab-group.mat-background-primary .mat-tab-label.mat-tab-disabled, .app-light .mat-tab-group.mat-background-primary .mat-tab-link.mat-tab-disabled, .app-light .mat-tab-nav-bar.mat-background-primary .mat-tab-label.mat-tab-disabled, .app-light .mat-tab-nav-bar.mat-background-primary .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app-light .mat-tab-group.mat-background-primary .mat-tab-header-pagination-chevron, .app-light .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-chevron {
  border-color: white;
}
.app-light .mat-tab-group.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app-light .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.app-light .mat-tab-group.mat-background-primary .mat-ripple-element, .app-light .mat-tab-nav-bar.mat-background-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.app-light .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app-light .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app-light .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app-light .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app-light .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app-light .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app-light .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app-light .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 229, 127, 0.3);
}
.app-light .mat-tab-group.mat-background-accent .mat-tab-header, .app-light .mat-tab-group.mat-background-accent .mat-tab-links, .app-light .mat-tab-group.mat-background-accent .mat-tab-header-pagination, .app-light .mat-tab-nav-bar.mat-background-accent .mat-tab-header, .app-light .mat-tab-nav-bar.mat-background-accent .mat-tab-links, .app-light .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination {
  background-color: #ffd740;
}
.app-light .mat-tab-group.mat-background-accent .mat-tab-label, .app-light .mat-tab-group.mat-background-accent .mat-tab-link, .app-light .mat-tab-nav-bar.mat-background-accent .mat-tab-label, .app-light .mat-tab-nav-bar.mat-background-accent .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.app-light .mat-tab-group.mat-background-accent .mat-tab-label.mat-tab-disabled, .app-light .mat-tab-group.mat-background-accent .mat-tab-link.mat-tab-disabled, .app-light .mat-tab-nav-bar.mat-background-accent .mat-tab-label.mat-tab-disabled, .app-light .mat-tab-nav-bar.mat-background-accent .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.app-light .mat-tab-group.mat-background-accent .mat-tab-header-pagination-chevron, .app-light .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.app-light .mat-tab-group.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app-light .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.4);
}
.app-light .mat-tab-group.mat-background-accent .mat-ripple-element, .app-light .mat-tab-nav-bar.mat-background-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.12);
}
.app-light .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app-light .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app-light .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app-light .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app-light .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app-light .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app-light .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app-light .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.app-light .mat-tab-group.mat-background-warn .mat-tab-header, .app-light .mat-tab-group.mat-background-warn .mat-tab-links, .app-light .mat-tab-group.mat-background-warn .mat-tab-header-pagination, .app-light .mat-tab-nav-bar.mat-background-warn .mat-tab-header, .app-light .mat-tab-nav-bar.mat-background-warn .mat-tab-links, .app-light .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination {
  background-color: #f44336;
}
.app-light .mat-tab-group.mat-background-warn .mat-tab-label, .app-light .mat-tab-group.mat-background-warn .mat-tab-link, .app-light .mat-tab-nav-bar.mat-background-warn .mat-tab-label, .app-light .mat-tab-nav-bar.mat-background-warn .mat-tab-link {
  color: white;
}
.app-light .mat-tab-group.mat-background-warn .mat-tab-label.mat-tab-disabled, .app-light .mat-tab-group.mat-background-warn .mat-tab-link.mat-tab-disabled, .app-light .mat-tab-nav-bar.mat-background-warn .mat-tab-label.mat-tab-disabled, .app-light .mat-tab-nav-bar.mat-background-warn .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app-light .mat-tab-group.mat-background-warn .mat-tab-header-pagination-chevron, .app-light .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-chevron {
  border-color: white;
}
.app-light .mat-tab-group.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app-light .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.app-light .mat-tab-group.mat-background-warn .mat-ripple-element, .app-light .mat-tab-nav-bar.mat-background-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.app-light .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.app-light .mat-toolbar.mat-primary {
  background: #007dbd;
  color: white;
}
.app-light .mat-toolbar.mat-accent {
  background: #ffd740;
  color: rgba(0, 0, 0, 0.87);
}
.app-light .mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.app-light .mat-toolbar .mat-form-field-underline,
.app-light .mat-toolbar .mat-form-field-ripple,
.app-light .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.app-light .mat-toolbar .mat-form-field-label,
.app-light .mat-toolbar .mat-focused .mat-form-field-label,
.app-light .mat-toolbar .mat-select-value,
.app-light .mat-toolbar .mat-select-arrow,
.app-light .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.app-light .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }

  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.app-light .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.app-light .mat-tree {
  background: white;
}
.app-light .mat-tree-node,
.app-light .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.mat-tree-node {
  min-height: 48px;
}

.app-light .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app-light .mat-simple-snackbar-action {
  color: #ffd740;
}

.app-dark .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app-dark .mat-option {
  color: white;
}
.app-dark .mat-option:hover:not(.mat-option-disabled), .app-dark .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.app-dark .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.app-dark .mat-option.mat-active {
  background: rgba(255, 255, 255, 0.04);
  color: white;
}
.app-dark .mat-option.mat-option-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #007dbd;
}
.app-dark .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ffd740;
}
.app-dark .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ff5722;
}
.app-dark .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.7);
}
.app-dark .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-pseudo-checkbox {
  color: rgba(255, 255, 255, 0.7);
}
.app-dark .mat-pseudo-checkbox::after {
  color: #303030;
}
.app-dark .mat-pseudo-checkbox-disabled {
  color: #686868;
}
.app-dark .mat-primary .mat-pseudo-checkbox-checked,
.app-dark .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #007dbd;
}
.app-dark .mat-pseudo-checkbox-checked,
.app-dark .mat-pseudo-checkbox-indeterminate,
.app-dark .mat-accent .mat-pseudo-checkbox-checked,
.app-dark .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #ffd740;
}
.app-dark .mat-warn .mat-pseudo-checkbox-checked,
.app-dark .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #ff5722;
}
.app-dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.app-dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #686868;
}
.app-dark .mat-app-background, .app-dark.mat-app-background {
  background-color: #303030;
  color: white;
}
.app-dark .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.app-dark .mat-autocomplete-panel {
  background: #424242;
  color: white;
}
.app-dark .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: #424242;
}
.app-dark .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: white;
}
.app-dark .mat-badge-content {
  color: white;
  background: #007dbd;
}
.cdk-high-contrast-active .app-dark .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.app-dark .mat-badge-accent .mat-badge-content {
  background: #ffd740;
  color: rgba(0, 0, 0, 0.87);
}
.app-dark .mat-badge-warn .mat-badge-content {
  color: white;
  background: #ff5722;
}
.app-dark .mat-badge {
  position: relative;
}
.app-dark .mat-badge-hidden .mat-badge-content {
  display: none;
}
.app-dark .mat-badge-disabled .mat-badge-content {
  background: #6e6e6e;
  color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.app-dark .ng-animate-disabled .mat-badge-content,
.app-dark .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.app-dark .mat-badge-content.mat-badge-active {
  transform: none;
}
.app-dark .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.app-dark .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.app-dark .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.app-dark .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .app-dark .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.app-dark .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .app-dark .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.app-dark .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .app-dark .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.app-dark .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .app-dark .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.app-dark .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.app-dark .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.app-dark .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.app-dark .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .app-dark .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.app-dark .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .app-dark .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.app-dark .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .app-dark .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.app-dark .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .app-dark .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.app-dark .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.app-dark .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.app-dark .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.app-dark .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .app-dark .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.app-dark .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .app-dark .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.app-dark .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .app-dark .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.app-dark .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .app-dark .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.app-dark .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.app-dark .mat-button, .app-dark .mat-icon-button, .app-dark .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.app-dark .mat-button.mat-primary, .app-dark .mat-icon-button.mat-primary, .app-dark .mat-stroked-button.mat-primary {
  color: #007dbd;
}
.app-dark .mat-button.mat-accent, .app-dark .mat-icon-button.mat-accent, .app-dark .mat-stroked-button.mat-accent {
  color: #ffd740;
}
.app-dark .mat-button.mat-warn, .app-dark .mat-icon-button.mat-warn, .app-dark .mat-stroked-button.mat-warn {
  color: #ff5722;
}
.app-dark .mat-button.mat-primary.mat-button-disabled, .app-dark .mat-button.mat-accent.mat-button-disabled, .app-dark .mat-button.mat-warn.mat-button-disabled, .app-dark .mat-button.mat-button-disabled.mat-button-disabled, .app-dark .mat-icon-button.mat-primary.mat-button-disabled, .app-dark .mat-icon-button.mat-accent.mat-button-disabled, .app-dark .mat-icon-button.mat-warn.mat-button-disabled, .app-dark .mat-icon-button.mat-button-disabled.mat-button-disabled, .app-dark .mat-stroked-button.mat-primary.mat-button-disabled, .app-dark .mat-stroked-button.mat-accent.mat-button-disabled, .app-dark .mat-stroked-button.mat-warn.mat-button-disabled, .app-dark .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.app-dark .mat-button.mat-primary .mat-button-focus-overlay, .app-dark .mat-icon-button.mat-primary .mat-button-focus-overlay, .app-dark .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #007dbd;
}
.app-dark .mat-button.mat-accent .mat-button-focus-overlay, .app-dark .mat-icon-button.mat-accent .mat-button-focus-overlay, .app-dark .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #ffd740;
}
.app-dark .mat-button.mat-warn .mat-button-focus-overlay, .app-dark .mat-icon-button.mat-warn .mat-button-focus-overlay, .app-dark .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #ff5722;
}
.app-dark .mat-button.mat-button-disabled .mat-button-focus-overlay, .app-dark .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .app-dark .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.app-dark .mat-button .mat-ripple-element, .app-dark .mat-icon-button .mat-ripple-element, .app-dark .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.app-dark .mat-button-focus-overlay {
  background: white;
}
.app-dark .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(255, 255, 255, 0.12);
}
.app-dark .mat-flat-button, .app-dark .mat-raised-button, .app-dark .mat-fab, .app-dark .mat-mini-fab {
  color: white;
  background-color: #424242;
}
.app-dark .mat-flat-button.mat-primary, .app-dark .mat-raised-button.mat-primary, .app-dark .mat-fab.mat-primary, .app-dark .mat-mini-fab.mat-primary {
  color: white;
}
.app-dark .mat-flat-button.mat-accent, .app-dark .mat-raised-button.mat-accent, .app-dark .mat-fab.mat-accent, .app-dark .mat-mini-fab.mat-accent {
  color: rgba(0, 0, 0, 0.87);
}
.app-dark .mat-flat-button.mat-warn, .app-dark .mat-raised-button.mat-warn, .app-dark .mat-fab.mat-warn, .app-dark .mat-mini-fab.mat-warn {
  color: white;
}
.app-dark .mat-flat-button.mat-primary.mat-button-disabled, .app-dark .mat-flat-button.mat-accent.mat-button-disabled, .app-dark .mat-flat-button.mat-warn.mat-button-disabled, .app-dark .mat-flat-button.mat-button-disabled.mat-button-disabled, .app-dark .mat-raised-button.mat-primary.mat-button-disabled, .app-dark .mat-raised-button.mat-accent.mat-button-disabled, .app-dark .mat-raised-button.mat-warn.mat-button-disabled, .app-dark .mat-raised-button.mat-button-disabled.mat-button-disabled, .app-dark .mat-fab.mat-primary.mat-button-disabled, .app-dark .mat-fab.mat-accent.mat-button-disabled, .app-dark .mat-fab.mat-warn.mat-button-disabled, .app-dark .mat-fab.mat-button-disabled.mat-button-disabled, .app-dark .mat-mini-fab.mat-primary.mat-button-disabled, .app-dark .mat-mini-fab.mat-accent.mat-button-disabled, .app-dark .mat-mini-fab.mat-warn.mat-button-disabled, .app-dark .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.app-dark .mat-flat-button.mat-primary, .app-dark .mat-raised-button.mat-primary, .app-dark .mat-fab.mat-primary, .app-dark .mat-mini-fab.mat-primary {
  background-color: #007dbd;
}
.app-dark .mat-flat-button.mat-accent, .app-dark .mat-raised-button.mat-accent, .app-dark .mat-fab.mat-accent, .app-dark .mat-mini-fab.mat-accent {
  background-color: #ffd740;
}
.app-dark .mat-flat-button.mat-warn, .app-dark .mat-raised-button.mat-warn, .app-dark .mat-fab.mat-warn, .app-dark .mat-mini-fab.mat-warn {
  background-color: #ff5722;
}
.app-dark .mat-flat-button.mat-primary.mat-button-disabled, .app-dark .mat-flat-button.mat-accent.mat-button-disabled, .app-dark .mat-flat-button.mat-warn.mat-button-disabled, .app-dark .mat-flat-button.mat-button-disabled.mat-button-disabled, .app-dark .mat-raised-button.mat-primary.mat-button-disabled, .app-dark .mat-raised-button.mat-accent.mat-button-disabled, .app-dark .mat-raised-button.mat-warn.mat-button-disabled, .app-dark .mat-raised-button.mat-button-disabled.mat-button-disabled, .app-dark .mat-fab.mat-primary.mat-button-disabled, .app-dark .mat-fab.mat-accent.mat-button-disabled, .app-dark .mat-fab.mat-warn.mat-button-disabled, .app-dark .mat-fab.mat-button-disabled.mat-button-disabled, .app-dark .mat-mini-fab.mat-primary.mat-button-disabled, .app-dark .mat-mini-fab.mat-accent.mat-button-disabled, .app-dark .mat-mini-fab.mat-warn.mat-button-disabled, .app-dark .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(255, 255, 255, 0.12);
}
.app-dark .mat-flat-button.mat-primary .mat-ripple-element, .app-dark .mat-raised-button.mat-primary .mat-ripple-element, .app-dark .mat-fab.mat-primary .mat-ripple-element, .app-dark .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app-dark .mat-flat-button.mat-accent .mat-ripple-element, .app-dark .mat-raised-button.mat-accent .mat-ripple-element, .app-dark .mat-fab.mat-accent .mat-ripple-element, .app-dark .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.app-dark .mat-flat-button.mat-warn .mat-ripple-element, .app-dark .mat-raised-button.mat-warn .mat-ripple-element, .app-dark .mat-fab.mat-warn .mat-ripple-element, .app-dark .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app-dark .mat-stroked-button:not([class*=mat-elevation-z]), .app-dark .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-fab:not([class*=mat-elevation-z]), .app-dark .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .app-dark .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .app-dark .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-button-toggle-standalone,
.app-dark .mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.app-dark .mat-button-toggle-group-appearance-standard {
  box-shadow: none;
}
.app-dark .mat-button-toggle {
  color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(255, 255, 255, 0.12);
}
.app-dark .mat-button-toggle-appearance-standard {
  color: white;
  background: #424242;
}
.app-dark .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: white;
}
.app-dark .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
}
.app-dark [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.app-dark .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px rgba(255, 255, 255, 0.12);
}
.app-dark .mat-button-toggle-checked {
  background-color: #212121;
  color: rgba(255, 255, 255, 0.7);
}
.app-dark .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: white;
}
.app-dark .mat-button-toggle-disabled {
  color: rgba(255, 255, 255, 0.3);
  background-color: black;
}
.app-dark .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: #424242;
}
.app-dark .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #424242;
}
.app-dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.app-dark .mat-button-toggle-group-appearance-standard {
  border: solid 1px rgba(255, 255, 255, 0.12);
}
.app-dark .mat-card {
  background: #424242;
  color: white;
}
.app-dark .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-card-subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.app-dark .mat-checkbox-frame {
  border-color: rgba(255, 255, 255, 0.7);
}
.app-dark .mat-checkbox-checkmark {
  fill: #303030;
}
.app-dark .mat-checkbox-checkmark-path {
  stroke: #303030 !important;
}
.app-dark .mat-checkbox-mixedmark {
  background-color: #303030;
}
.app-dark .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .app-dark .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #007dbd;
}
.app-dark .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .app-dark .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #ffd740;
}
.app-dark .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .app-dark .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #ff5722;
}
.app-dark .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .app-dark .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #686868;
}
.app-dark .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #686868;
}
.app-dark .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(255, 255, 255, 0.7);
}
.app-dark .mat-checkbox .mat-ripple-element {
  background-color: white;
}
.app-dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.app-dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #007dbd;
}
.app-dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.app-dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #ffd740;
}
.app-dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.app-dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #ff5722;
}
.app-dark .mat-chip.mat-standard-chip {
  background-color: #616161;
  color: white;
}
.app-dark .mat-chip.mat-standard-chip .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app-dark .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.app-dark .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.app-dark .mat-chip.mat-standard-chip::after {
  background: white;
}
.app-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #007dbd;
  color: white;
}
.app-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #ff5722;
  color: white;
}
.app-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #ffd740;
  color: rgba(0, 0, 0, 0.87);
}
.app-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.app-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.app-dark .mat-table {
  background: #424242;
}
.app-dark .mat-table thead, .app-dark .mat-table tbody, .app-dark .mat-table tfoot,
.app-dark mat-header-row, .app-dark mat-row, .app-dark mat-footer-row,
.app-dark [mat-header-row], .app-dark [mat-row], .app-dark [mat-footer-row],
.app-dark .mat-table-sticky {
  background: inherit;
}
.app-dark mat-row, .app-dark mat-header-row, .app-dark mat-footer-row,
.app-dark th.mat-header-cell, .app-dark td.mat-cell, .app-dark td.mat-footer-cell {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.app-dark .mat-header-cell {
  color: rgba(255, 255, 255, 0.7);
}
.app-dark .mat-cell, .app-dark .mat-footer-cell {
  color: white;
}
.app-dark .mat-calendar-arrow {
  border-top-color: white;
}
.app-dark .mat-datepicker-toggle,
.app-dark .mat-datepicker-content .mat-calendar-next-button,
.app-dark .mat-datepicker-content .mat-calendar-previous-button {
  color: white;
}
.app-dark .mat-calendar-table-header {
  color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-calendar-table-header-divider::after {
  background: rgba(255, 255, 255, 0.12);
}
.app-dark .mat-calendar-body-label {
  color: rgba(255, 255, 255, 0.7);
}
.app-dark .mat-calendar-body-cell-content,
.app-dark .mat-date-range-input-separator {
  color: white;
  border-color: transparent;
}
.app-dark .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.app-dark .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.app-dark .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.app-dark .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  background-color: rgba(255, 255, 255, 0.04);
}
.app-dark .mat-calendar-body-in-preview {
  color: rgba(255, 255, 255, 0.24);
}
.app-dark .mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: rgba(255, 255, 255, 0.3);
}
.app-dark .mat-calendar-body-in-range::before {
  background: rgba(0, 125, 189, 0.2);
}
.app-dark .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app-dark .mat-calendar-body-comparison-bridge-start::before,
.app-dark [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(0, 125, 189, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app-dark .mat-calendar-body-comparison-bridge-end::before,
.app-dark [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(0, 125, 189, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app-dark .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app-dark .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app-dark .mat-calendar-body-selected {
  background-color: #007dbd;
  color: white;
}
.app-dark .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(0, 125, 189, 0.4);
}
.app-dark .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app-dark .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #424242;
  color: white;
}
.app-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(255, 215, 64, 0.2);
}
.app-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.app-dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 215, 64, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.app-dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 215, 64, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app-dark .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #ffd740;
  color: rgba(0, 0, 0, 0.87);
}
.app-dark .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 215, 64, 0.4);
}
.app-dark .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.app-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(255, 87, 34, 0.2);
}
.app-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.app-dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 87, 34, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.app-dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 87, 34, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app-dark .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #ff5722;
  color: white;
}
.app-dark .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 87, 34, 0.4);
}
.app-dark .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app-dark .mat-datepicker-content-touch {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-datepicker-toggle-active {
  color: #007dbd;
}
.app-dark .mat-datepicker-toggle-active.mat-accent {
  color: #ffd740;
}
.app-dark .mat-datepicker-toggle-active.mat-warn {
  color: #ff5722;
}
.app-dark .mat-date-range-input-inner[disabled] {
  color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.app-dark .mat-divider {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.app-dark .mat-divider-vertical {
  border-right-color: rgba(255, 255, 255, 0.12);
}
.app-dark .mat-expansion-panel {
  background: #424242;
  color: white;
}
.app-dark .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-action-row {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.app-dark .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .app-dark .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .app-dark .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(255, 255, 255, 0.04);
}
@media (hover: none) {
  .app-dark .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: #424242;
  }
}
.app-dark .mat-expansion-panel-header-title {
  color: white;
}
.app-dark .mat-expansion-panel-header-description,
.app-dark .mat-expansion-indicator::after {
  color: rgba(255, 255, 255, 0.7);
}
.app-dark .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(255, 255, 255, 0.3);
}
.app-dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.app-dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.app-dark .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.app-dark .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.app-dark .mat-form-field.mat-focused .mat-form-field-label {
  color: #007dbd;
}
.app-dark .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #ffd740;
}
.app-dark .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #ff5722;
}
.app-dark .mat-focused .mat-form-field-required-marker {
  color: #ffd740;
}
.app-dark .mat-form-field-ripple {
  background-color: white;
}
.app-dark .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #007dbd;
}
.app-dark .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #ffd740;
}
.app-dark .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #ff5722;
}
.app-dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #007dbd;
}
.app-dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #ffd740;
}
.app-dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #ff5722;
}
.app-dark .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #ff5722;
}
.app-dark .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.app-dark .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #ff5722;
}
.app-dark .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.app-dark .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #ff5722;
}
.app-dark .mat-error {
  color: #ff5722;
}
.app-dark .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.app-dark .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.app-dark .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.app-dark .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.app-dark .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.app-dark .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.app-dark .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.1);
}
.app-dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.05);
}
.app-dark .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.app-dark .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.3);
}
.app-dark .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: white;
}
.app-dark .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #007dbd;
}
.app-dark .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #ffd740;
}
.app-dark .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #ff5722;
}
.app-dark .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #ff5722;
}
.app-dark .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.15);
}
.app-dark .mat-icon.mat-primary {
  color: #007dbd;
}
.app-dark .mat-icon.mat-accent {
  color: #ffd740;
}
.app-dark .mat-icon.mat-warn {
  color: #ff5722;
}
.app-dark .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.7);
}
.app-dark .mat-input-element:disabled,
.app-dark .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-input-element {
  caret-color: #007dbd;
}
.app-dark .mat-input-element::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-input-element::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-input-element::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-input-element:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-input-element option {
  color: rgba(0, 0, 0, 0.87);
}
.app-dark .mat-input-element option:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.app-dark .mat-form-field.mat-accent .mat-input-element {
  caret-color: #ffd740;
}
.app-dark .mat-form-field.mat-warn .mat-input-element,
.app-dark .mat-form-field-invalid .mat-input-element {
  caret-color: #ff5722;
}
.app-dark .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #ff5722;
}
.app-dark .mat-list-base .mat-list-item {
  color: white;
}
.app-dark .mat-list-base .mat-list-option {
  color: white;
}
.app-dark .mat-list-base .mat-subheader {
  color: rgba(255, 255, 255, 0.7);
}
.app-dark .mat-list-item-disabled {
  background-color: black;
}
.app-dark .mat-list-option:hover, .app-dark .mat-list-option:focus,
.app-dark .mat-nav-list .mat-list-item:hover,
.app-dark .mat-nav-list .mat-list-item:focus,
.app-dark .mat-action-list .mat-list-item:hover,
.app-dark .mat-action-list .mat-list-item:focus {
  background: rgba(255, 255, 255, 0.04);
}
.app-dark .mat-list-single-selected-option, .app-dark .mat-list-single-selected-option:hover, .app-dark .mat-list-single-selected-option:focus {
  background: rgba(255, 255, 255, 0.12);
}
.app-dark .mat-menu-panel {
  background: #424242;
}
.app-dark .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-menu-item {
  background: transparent;
  color: white;
}
.app-dark .mat-menu-item[disabled], .app-dark .mat-menu-item[disabled]::after {
  color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-menu-item .mat-icon-no-color,
.app-dark .mat-menu-item-submenu-trigger::after {
  color: white;
}
.app-dark .mat-menu-item:hover:not([disabled]),
.app-dark .mat-menu-item.cdk-program-focused:not([disabled]),
.app-dark .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.app-dark .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(255, 255, 255, 0.04);
}
.app-dark .mat-paginator {
  background: #424242;
}
.app-dark .mat-paginator,
.app-dark .mat-paginator-page-size .mat-select-trigger {
  color: rgba(255, 255, 255, 0.7);
}
.app-dark .mat-paginator-decrement,
.app-dark .mat-paginator-increment {
  border-top: 2px solid white;
  border-right: 2px solid white;
}
.app-dark .mat-paginator-first,
.app-dark .mat-paginator-last {
  border-top: 2px solid white;
}
.app-dark .mat-icon-button[disabled] .mat-paginator-decrement,
.app-dark .mat-icon-button[disabled] .mat-paginator-increment,
.app-dark .mat-icon-button[disabled] .mat-paginator-first,
.app-dark .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-progress-bar-background {
  fill: #b3d8eb;
}
.app-dark .mat-progress-bar-buffer {
  background-color: #b3d8eb;
}
.app-dark .mat-progress-bar-fill::after {
  background-color: #007dbd;
}
.app-dark .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #ffe57f;
}
.app-dark .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #ffe57f;
}
.app-dark .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #ffd740;
}
.app-dark .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #ffccbc;
}
.app-dark .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #ffccbc;
}
.app-dark .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #ff5722;
}
.app-dark .mat-progress-spinner circle, .app-dark .mat-spinner circle {
  stroke: #007dbd;
}
.app-dark .mat-progress-spinner.mat-accent circle, .app-dark .mat-spinner.mat-accent circle {
  stroke: #ffd740;
}
.app-dark .mat-progress-spinner.mat-warn circle, .app-dark .mat-spinner.mat-warn circle {
  stroke: #ff5722;
}
.app-dark .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.7);
}
.app-dark .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #007dbd;
}
.app-dark .mat-radio-button.mat-primary .mat-radio-inner-circle,
.app-dark .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app-dark .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .app-dark .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #007dbd;
}
.app-dark .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ffd740;
}
.app-dark .mat-radio-button.mat-accent .mat-radio-inner-circle,
.app-dark .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app-dark .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .app-dark .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #ffd740;
}
.app-dark .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ff5722;
}
.app-dark .mat-radio-button.mat-warn .mat-radio-inner-circle,
.app-dark .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app-dark .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .app-dark .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #ff5722;
}
.app-dark .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.app-dark .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.app-dark .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-radio-button .mat-ripple-element {
  background-color: white;
}
.app-dark .mat-select-value {
  color: white;
}
.app-dark .mat-select-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-select-disabled .mat-select-value {
  color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-select-arrow {
  color: rgba(255, 255, 255, 0.7);
}
.app-dark .mat-select-panel {
  background: #424242;
}
.app-dark .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(255, 255, 255, 0.12);
}
.app-dark .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #007dbd;
}
.app-dark .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #ffd740;
}
.app-dark .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #ff5722;
}
.app-dark .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #ff5722;
}
.app-dark .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-drawer-container {
  background-color: #303030;
  color: white;
}
.app-dark .mat-drawer {
  background-color: #424242;
  color: white;
}
.app-dark .mat-drawer.mat-drawer-push {
  background-color: #424242;
}
.app-dark .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-drawer-side {
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.app-dark .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.app-dark [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.app-dark [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.app-dark .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(189, 189, 189, 0.6);
}
.app-dark .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #ffd740;
}
.app-dark .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 215, 64, 0.54);
}
.app-dark .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #ffd740;
}
.app-dark .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #007dbd;
}
.app-dark .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(0, 125, 189, 0.54);
}
.app-dark .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #007dbd;
}
.app-dark .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #ff5722;
}
.app-dark .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 87, 34, 0.54);
}
.app-dark .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #ff5722;
}
.app-dark .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: white;
}
.app-dark .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #bdbdbd;
}
.app-dark .mat-slide-toggle-bar {
  background-color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.app-dark .mat-primary .mat-slider-track-fill,
.app-dark .mat-primary .mat-slider-thumb,
.app-dark .mat-primary .mat-slider-thumb-label {
  background-color: #007dbd;
}
.app-dark .mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.app-dark .mat-primary .mat-slider-focus-ring {
  background-color: rgba(0, 125, 189, 0.2);
}
.app-dark .mat-accent .mat-slider-track-fill,
.app-dark .mat-accent .mat-slider-thumb,
.app-dark .mat-accent .mat-slider-thumb-label {
  background-color: #ffd740;
}
.app-dark .mat-accent .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.app-dark .mat-accent .mat-slider-focus-ring {
  background-color: rgba(255, 215, 64, 0.2);
}
.app-dark .mat-warn .mat-slider-track-fill,
.app-dark .mat-warn .mat-slider-thumb,
.app-dark .mat-warn .mat-slider-thumb-label {
  background-color: #ff5722;
}
.app-dark .mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.app-dark .mat-warn .mat-slider-focus-ring {
  background-color: rgba(255, 87, 34, 0.2);
}
.app-dark .mat-slider:hover .mat-slider-track-background,
.app-dark .cdk-focused .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.app-dark .mat-slider-disabled .mat-slider-track-background,
.app-dark .mat-slider-disabled .mat-slider-track-fill,
.app-dark .mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(255, 255, 255, 0.3);
}
.app-dark .mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.app-dark .mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(255, 255, 255, 0.12);
}
.app-dark .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.app-dark .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: white;
}
.app-dark .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.app-dark .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(255, 255, 255, 0.3);
}
.app-dark .mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
  background-color: transparent;
}
.app-dark .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .app-dark .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.app-dark .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .app-dark .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.app-dark .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(255, 255, 255, 0.7);
}
.app-dark .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.app-dark .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.app-dark .mat-step-header.cdk-keyboard-focused, .app-dark .mat-step-header.cdk-program-focused, .app-dark .mat-step-header:hover {
  background-color: rgba(255, 255, 255, 0.04);
}
@media (hover: none) {
  .app-dark .mat-step-header:hover {
    background: none;
  }
}
.app-dark .mat-step-header .mat-step-label,
.app-dark .mat-step-header .mat-step-optional {
  color: rgba(255, 255, 255, 0.7);
}
.app-dark .mat-step-header .mat-step-icon {
  background-color: rgba(255, 255, 255, 0.7);
  color: white;
}
.app-dark .mat-step-header .mat-step-icon-selected,
.app-dark .mat-step-header .mat-step-icon-state-done,
.app-dark .mat-step-header .mat-step-icon-state-edit {
  background-color: #007dbd;
  color: white;
}
.app-dark .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #ff5722;
}
.app-dark .mat-step-header .mat-step-label.mat-step-label-active {
  color: white;
}
.app-dark .mat-step-header .mat-step-label.mat-step-label-error {
  color: #ff5722;
}
.app-dark .mat-stepper-horizontal, .app-dark .mat-stepper-vertical {
  background-color: #424242;
}
.app-dark .mat-stepper-vertical-line::before {
  border-left-color: rgba(255, 255, 255, 0.12);
}
.app-dark .mat-horizontal-stepper-header::before,
.app-dark .mat-horizontal-stepper-header::after,
.app-dark .mat-stepper-horizontal-line {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.app-dark .mat-sort-header-arrow {
  color: #c6c6c6;
}
.app-dark .mat-tab-nav-bar,
.app-dark .mat-tab-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.app-dark .mat-tab-group-inverted-header .mat-tab-nav-bar,
.app-dark .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  border-bottom: none;
}
.app-dark .mat-tab-label, .app-dark .mat-tab-link {
  color: white;
}
.app-dark .mat-tab-label.mat-tab-disabled, .app-dark .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-tab-header-pagination-chevron {
  border-color: white;
}
.app-dark .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-tab-group[class*=mat-background-] .mat-tab-header,
.app-dark .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.app-dark .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app-dark .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app-dark .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app-dark .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(179, 216, 235, 0.3);
}
.app-dark .mat-tab-group.mat-primary .mat-ink-bar, .app-dark .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #007dbd;
}
.app-dark .mat-tab-group.mat-primary.mat-background-primary .mat-ink-bar, .app-dark .mat-tab-nav-bar.mat-primary.mat-background-primary .mat-ink-bar {
  background-color: white;
}
.app-dark .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app-dark .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app-dark .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app-dark .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 229, 127, 0.3);
}
.app-dark .mat-tab-group.mat-accent .mat-ink-bar, .app-dark .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #ffd740;
}
.app-dark .mat-tab-group.mat-accent.mat-background-accent .mat-ink-bar, .app-dark .mat-tab-nav-bar.mat-accent.mat-background-accent .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.app-dark .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app-dark .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app-dark .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app-dark .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 204, 188, 0.3);
}
.app-dark .mat-tab-group.mat-warn .mat-ink-bar, .app-dark .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #ff5722;
}
.app-dark .mat-tab-group.mat-warn.mat-background-warn .mat-ink-bar, .app-dark .mat-tab-nav-bar.mat-warn.mat-background-warn .mat-ink-bar {
  background-color: white;
}
.app-dark .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app-dark .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(179, 216, 235, 0.3);
}
.app-dark .mat-tab-group.mat-background-primary .mat-tab-header, .app-dark .mat-tab-group.mat-background-primary .mat-tab-links, .app-dark .mat-tab-group.mat-background-primary .mat-tab-header-pagination, .app-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-header, .app-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-links, .app-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination {
  background-color: #007dbd;
}
.app-dark .mat-tab-group.mat-background-primary .mat-tab-label, .app-dark .mat-tab-group.mat-background-primary .mat-tab-link, .app-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label, .app-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link {
  color: white;
}
.app-dark .mat-tab-group.mat-background-primary .mat-tab-label.mat-tab-disabled, .app-dark .mat-tab-group.mat-background-primary .mat-tab-link.mat-tab-disabled, .app-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label.mat-tab-disabled, .app-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app-dark .mat-tab-group.mat-background-primary .mat-tab-header-pagination-chevron, .app-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-chevron {
  border-color: white;
}
.app-dark .mat-tab-group.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.app-dark .mat-tab-group.mat-background-primary .mat-ripple-element, .app-dark .mat-tab-nav-bar.mat-background-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.app-dark .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app-dark .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 229, 127, 0.3);
}
.app-dark .mat-tab-group.mat-background-accent .mat-tab-header, .app-dark .mat-tab-group.mat-background-accent .mat-tab-links, .app-dark .mat-tab-group.mat-background-accent .mat-tab-header-pagination, .app-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-header, .app-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-links, .app-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination {
  background-color: #ffd740;
}
.app-dark .mat-tab-group.mat-background-accent .mat-tab-label, .app-dark .mat-tab-group.mat-background-accent .mat-tab-link, .app-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label, .app-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.app-dark .mat-tab-group.mat-background-accent .mat-tab-label.mat-tab-disabled, .app-dark .mat-tab-group.mat-background-accent .mat-tab-link.mat-tab-disabled, .app-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label.mat-tab-disabled, .app-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.app-dark .mat-tab-group.mat-background-accent .mat-tab-header-pagination-chevron, .app-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.app-dark .mat-tab-group.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.4);
}
.app-dark .mat-tab-group.mat-background-accent .mat-ripple-element, .app-dark .mat-tab-nav-bar.mat-background-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.12);
}
.app-dark .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app-dark .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 204, 188, 0.3);
}
.app-dark .mat-tab-group.mat-background-warn .mat-tab-header, .app-dark .mat-tab-group.mat-background-warn .mat-tab-links, .app-dark .mat-tab-group.mat-background-warn .mat-tab-header-pagination, .app-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-header, .app-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-links, .app-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination {
  background-color: #ff5722;
}
.app-dark .mat-tab-group.mat-background-warn .mat-tab-label, .app-dark .mat-tab-group.mat-background-warn .mat-tab-link, .app-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label, .app-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link {
  color: white;
}
.app-dark .mat-tab-group.mat-background-warn .mat-tab-label.mat-tab-disabled, .app-dark .mat-tab-group.mat-background-warn .mat-tab-link.mat-tab-disabled, .app-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label.mat-tab-disabled, .app-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app-dark .mat-tab-group.mat-background-warn .mat-tab-header-pagination-chevron, .app-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-chevron {
  border-color: white;
}
.app-dark .mat-tab-group.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.app-dark .mat-tab-group.mat-background-warn .mat-ripple-element, .app-dark .mat-tab-nav-bar.mat-background-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.app-dark .mat-toolbar {
  background: #212121;
  color: white;
}
.app-dark .mat-toolbar.mat-primary {
  background: #007dbd;
  color: white;
}
.app-dark .mat-toolbar.mat-accent {
  background: #ffd740;
  color: rgba(0, 0, 0, 0.87);
}
.app-dark .mat-toolbar.mat-warn {
  background: #ff5722;
  color: white;
}
.app-dark .mat-toolbar .mat-form-field-underline,
.app-dark .mat-toolbar .mat-form-field-ripple,
.app-dark .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.app-dark .mat-toolbar .mat-form-field-label,
.app-dark .mat-toolbar .mat-focused .mat-form-field-label,
.app-dark .mat-toolbar .mat-select-value,
.app-dark .mat-toolbar .mat-select-arrow,
.app-dark .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.app-dark .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.app-dark .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.app-dark .mat-tree {
  background: #424242;
}
.app-dark .mat-tree-node,
.app-dark .mat-nested-tree-node {
  color: white;
}
.app-dark .mat-snack-bar-container {
  color: rgba(0, 0, 0, 0.87);
  background: #fafafa;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-simple-snackbar-action {
  color: inherit;
}

.ps-container {
  -ms-touch-action: auto;
  touch-action: auto;
  overflow: hidden !important;
  -ms-overflow-style: none;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps-container {
    overflow: auto !important;
  }
}
.ps-container.ps-active-x > .ps-scrollbar-x-rail, .ps-container.ps-active-y > .ps-scrollbar-y-rail {
  display: block;
  background-color: transparent;
}
.ps-container.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail {
  background-color: #eee;
  opacity: 0.9;
}
.ps-container.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail > .ps-scrollbar-x {
  background-color: #999;
  height: 11px;
}
.ps-container.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
  background-color: #eee;
  opacity: 0.9;
}
.ps-container.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail > .ps-scrollbar-y {
  background-color: #999;
  width: 11px;
}
.ps-container > .ps-scrollbar-x-rail {
  display: none;
  position: absolute;
  /* please don't change 'position' */
  opacity: 0;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  bottom: 0px;
  /* there must be 'bottom' for ps-scrollbar-x-rail */
  height: 15px;
}
.ps-container > .ps-scrollbar-x-rail > .ps-scrollbar-x {
  position: absolute;
  /* please don't change 'position' */
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color 0.2s linear, height 0.2s linear, width 0.2s ease-in-out, border-radius 0.2s ease-in-out;
  bottom: 2px;
  /* there must be 'bottom' for ps-scrollbar-x */
  height: 6px;
}
.ps-container > .ps-scrollbar-x-rail:hover > .ps-scrollbar-x, .ps-container > .ps-scrollbar-x-rail:active > .ps-scrollbar-x {
  height: 11px;
}
.ps-container > .ps-scrollbar-y-rail {
  display: none;
  position: absolute;
  /* please don't change 'position' */
  opacity: 0;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  right: 0;
  /* there must be 'right' for ps-scrollbar-y-rail */
  width: 15px;
}
.ps-container > .ps-scrollbar-y-rail > .ps-scrollbar-y {
  position: absolute;
  /* please don't change 'position' */
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color 0.2s linear, height 0.2s linear, width 0.2s ease-in-out, border-radius 0.2s ease-in-out;
  right: 2px;
  /* there must be 'right' for ps-scrollbar-y */
  width: 6px;
}
.ps-container > .ps-scrollbar-y-rail:hover > .ps-scrollbar-y, .ps-container > .ps-scrollbar-y-rail:active > .ps-scrollbar-y {
  width: 11px;
}
.ps-container:hover.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail {
  background-color: #eee;
  opacity: 0.9;
}
.ps-container:hover.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail > .ps-scrollbar-x {
  background-color: #999;
  height: 11px;
}
.ps-container:hover.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
  background-color: #eee;
  opacity: 0.9;
}
.ps-container:hover.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail > .ps-scrollbar-y {
  background-color: #999;
  width: 11px;
}
.ps-container:hover > .ps-scrollbar-x-rail,
.ps-container:hover > .ps-scrollbar-y-rail {
  opacity: 0.6;
}
.ps-container:hover > .ps-scrollbar-x-rail:hover {
  background-color: #eee;
  opacity: 0.9;
}
.ps-container:hover > .ps-scrollbar-x-rail:hover > .ps-scrollbar-x {
  background-color: #999;
}
.ps-container:hover > .ps-scrollbar-y-rail:hover {
  background-color: #eee;
  opacity: 0.9;
}
.ps-container:hover > .ps-scrollbar-y-rail:hover > .ps-scrollbar-y {
  background-color: #999;
}

/* $scaffolding
 ------------------------------------------*/
html {
  font-size: 16px;
  font-family: "Muli", "Helvetica Neue", Helvetica, sans-serif;
}

html,
body {
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  min-height: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.87);
  line-height: 1.5;
  font-family: "Muli", "Helvetica Neue", Helvetica, sans-serif;
}
body.pace-done {
  background: #6b6b6b;
}

[tabindex="-1"]:focus {
  outline: none;
}

select,
button,
textarea,
input {
  vertical-align: baseline;
}

html[dir=rtl], html[dir=ltr],
body[dir=rtl],
body[dir=ltr] {
  unicode-bidi: embed;
}

bdo[dir=rtl] {
  direction: rtl;
  unicode-bidi: bidi-override;
}

bdo[dir=ltr] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

.settings-panel {
  position: fixed;
  bottom: 6px;
  right: 6px;
  width: 300px;
  z-index: 9;
}

/* $typography
 ------------------------------------------*/
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-weight: 400;
  line-height: 1.1;
  color: inherit;
}

h1,
.h1 {
  font-size: 2.5rem;
}

h2,
.h2 {
  font-size: 2rem;
}

h3,
.h3 {
  font-size: 1.75rem;
}

h4,
.h4 {
  font-size: 1.5rem;
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1rem;
}

a {
  text-decoration: none;
  color: inherit;
}
a:focus, a:hover {
  cursor: pointer;
  text-decoration: none;
  outline: 0;
}

button {
  outline: 0;
}

figure {
  margin: 0;
}

hr {
  margin-top: 0;
  margin-bottom: 0;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

strong,
b {
  font-weight: 700;
}

/* $sidebar-panel
 ------------------------------------------*/
mat-sidenav.sidebar-panel {
  overflow-x: hidden;
  width: 15rem;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
}
mat-sidenav.sidebar-panel .mat-sidenav-focus-trap > .cdk-focus-trap-content {
  position: relative;
}
mat-sidenav.sidebar-panel > nav {
  min-width: 15rem;
}
mat-sidenav.sidebar-panel mat-list-item .mat-list-item {
  display: block;
  height: auto;
  max-height: 48px;
  overflow: hidden;
  padding: 0;
  -webkit-transition: max-height 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  -moz-transition: max-height 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  transition: max-height 0.3s cubic-bezier(0.35, 0, 0.25, 1);
}
mat-sidenav.sidebar-panel mat-list-item {
  background-color: transparent;
  -webkit-transition: background-color 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  -moz-transition: background-color 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  transition: background-color 0.3s cubic-bezier(0.35, 0, 0.25, 1);
}
mat-sidenav.sidebar-panel mat-list-item.open > .mat-list-item {
  max-height: 1500px;
  background: rgba(0, 0, 0, 0.04);
}
mat-sidenav.sidebar-panel mat-nav-list a {
  font-family: "Muli", "Helvetica Neue", Helvetica, sans-serif;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 48px;
  padding: 0 16px;
}
mat-sidenav.sidebar-panel .sub-menu {
  padding-top: 0;
  overflow: hidden;
  -webkit-transition: 0.5s cubic-bezier(0.35, 0, 0.25, 1);
  -moz-transition: 0.5s cubic-bezier(0.35, 0, 0.25, 1);
  transition: 0.5s cubic-bezier(0.35, 0, 0.25, 1);
  -webkit-transition-property: max-height;
  -moz-transition-property: max-height;
  transition-property: max-height;
}
mat-sidenav.sidebar-panel .sub-menu a {
  padding-left: 64px;
}
mat-sidenav.sidebar-panel .sub-menu .sub-menu a {
  padding-left: 80px;
}
mat-sidenav.sidebar-panel .sub-menu .sub-menu .sub-menu a {
  padding-left: 96px;
}
mat-sidenav.sidebar-panel .sub-menu .sub-menu .sub-menu .sub-menu a {
  padding-left: 112px;
}
mat-sidenav.sidebar-panel .navigation mat-icon:not(.menu-caret) {
  margin-right: 24px;
}
mat-sidenav.sidebar-panel .navigation .menu-caret {
  display: inline-block;
  -webkit-transition: -webkit-transform 300ms cubic-bezier(0.7, 0, 0.3, 1);
  -moz-transition: -moz-transform 300ms cubic-bezier(0.7, 0, 0.3, 1);
  -o-transition: -o-transform 300ms cubic-bezier(0.7, 0, 0.3, 1);
  transition: transform 300ms cubic-bezier(0.7, 0, 0.3, 1);
  float: right;
  margin-left: 0.3125rem;
  text-align: center;
}
mat-sidenav.sidebar-panel .navigation .open > .mat-list-item > .accordion-toggle > .menu-caret {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
mat-sidenav.sidebar-panel .navigation .menu-badge {
  display: inline-block;
  height: 18px;
  min-width: 10px;
  line-height: 20px;
  text-align: center;
  border-radius: 18px;
  font-size: 11px;
  font-weight: 700;
  padding: 0 4px;
}
mat-sidenav.sidebar-panel .navigation .open > .mat-list-item > .sub-menu {
  max-height: 1000px;
}

[dir=rtl] mat-sidenav.sidebar-panel .sub-menu a {
  padding-right: 64px;
  padding-left: 16px;
}
[dir=rtl] mat-sidenav.sidebar-panel .sub-menu .sub-menu a {
  padding-right: 80px;
  padding-left: 16px;
}
[dir=rtl] mat-sidenav.sidebar-panel .sub-menu .sub-menu .sub-menu a {
  padding-right: 96px;
  padding-left: 16px;
}
[dir=rtl] mat-sidenav.sidebar-panel .sub-menu .sub-menu .sub-menu .sub-menu a {
  padding-right: 112px;
  padding-left: 16px;
}
[dir=rtl] mat-sidenav.sidebar-panel .navigation mat-icon:not(.menu-caret) {
  margin-left: 24px;
  margin-right: 0;
}
[dir=rtl] mat-sidenav.sidebar-panel .navigation .menu-caret {
  float: left;
  margin-right: 0.3125rem;
  margin-left: 0;
}

/* $header
 ------------------------------------------*/
mat-toolbar .toolbar-avatar {
  width: 40px;
  height: 40px;
  line-height: 24px;
}
mat-toolbar .toolbar-avatar img {
  width: 40px;
  border-radius: 50%;
}
mat-toolbar .notification-label {
  position: absolute;
  top: 0;
  left: 50%;
  font-size: 12px;
  font-weight: 700;
  line-height: 13px;
  border-radius: 50%;
  width: 13px;
  height: 13px;
  background-color: #f44336;
  border: 4px solid #f44336;
  color: white;
  text-align: center;
}
mat-toolbar.main-header {
  padding: 0 8px;
  position: relative;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  z-index: 1;
}
mat-toolbar.main-header .branding {
  display: block;
  white-space: nowrap;
  margin: auto 0;
  line-height: 50px;
  padding: 0 64px 0 16px;
  max-width: 120px;
}
mat-toolbar.main-header .logo {
  display: inline;
  background-image: url(/assets/images/logo.png);
  width: 95px;
  height: 25px;
  -webkit-background-size: 95px 25px;
  background-size: 95px 25px;
  margin: auto;
}
mat-toolbar.main-header .container {
  width: auto;
  line-height: 25px;
  text-align: center;
}
mat-toolbar.main-header .logoMasterloop {
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
}
mat-toolbar.main-header .search-bar .search-form {
  background: white;
  position: relative;
  margin-right: 1rem;
  display: block;
  margin-bottom: 0;
  max-width: 400px;
}
@media (max-width: 767px) {
  mat-toolbar.main-header .search-bar .search-form {
    margin-right: 0;
  }
}
mat-toolbar.main-header .search-bar .search-form input {
  color: #000;
  font-family: "Muli", "Helvetica Neue", Helvetica, sans-serif;
  font-size: 1rem;
  height: 50px;
  padding: 10px;
  z-index: 2;
  cursor: text;
  text-indent: 30px;
  border: none;
  background: transparent;
  width: 100%;
  outline: 0;
  border-radius: 0;
}
mat-toolbar.main-header .search-bar .search-form .material-icons {
  position: absolute;
  top: 50%;
  left: 10px;
  margin-top: -12px;
  color: rgba(0, 0, 0, 0.87);
}
mat-toolbar.main-header .mat-icon-button {
  height: 35px;
  margin-left: 8px;
  width: 35px;
}
mat-toolbar.main-header .mobile-menu-button {
  margin-left: 0;
  margin-right: 8px;
}

@media (max-width: 599px) and (orientation: portrait) {
  mat-toolbar.main-header .branding {
    padding: 0 16px 0 16px;
  }
  mat-toolbar.main-header .logo {
    width: 64px;
    height: 17px;
    -webkit-background-size: 64px 17px;
    background-size: 64px 17px;
  }
}
[dir=rtl].main-header .branding {
  padding: 0 16px 0 64px;
}
[dir=rtl].main-header .search-bar .search-form .material-icons {
  font-family: "Muli", "Helvetica Neue", Helvetica, sans-serif;
  left: auto;
  right: 10px;
}

/* $main-panel
 ------------------------------------------*/
.app-inner {
  position: relative;
  width: 100%;
  max-width: 100%;
  height: calc(100vh - 64px);
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

@media (max-width: 599px) and (orientation: portrait) {
  .app-inner {
    height: calc(100vh - 56px);
  }
}
@media (max-width: 959px) and (orientation: landscape) {
  .app-inner {
    height: calc(100vh - 48px);
  }
}
/* Boxed layout*/
.app.boxed {
  overflow: hidden;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 48em) {
  .app.boxed {
    max-width: 46.88rem;
  }
}
@media (min-width: 62rem) {
  .app.boxed {
    max-width: 60.63rem;
  }
}
@media (min-width: 75rem) {
  .app.boxed {
    max-width: 73.13rem;
  }
}
.mat-sidenav-content {
  min-height: 100%;
  box-sizing: border-box;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0.3333333333rem;
}
.mat-sidenav-content > .main-content {
  box-sizing: border-box;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.mat-sidenav-content > .main-content > .content-view {
  position: relative;
  box-sizing: border-box;
  padding: 1rem;
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
  -moz-box-flex: 1;
  -moz-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
}

.sidebar-panel {
  padding-top: 8px;
}
.sidebar-panel .mat-expansion-panel-header {
  font-family: "Muli", "Helvetica Neue", Helvetica, sans-serif;
  padding: 0 16px;
}
.sidebar-panel .mat-expansion-panel-header .mat-icon {
  margin-right: 24px;
}
.sidebar-panel .mat-expansion-panel-header.mat-expanded {
  height: 48px;
}
.sidebar-panel .mat-expansion-panel-header span {
  font-size: 14px;
}
.sidebar-panel .mat-expansion-panel-content {
  background-color: rgba(238, 238, 238, 0.8);
  max-height: 575px;
  overflow: auto;
}
.sidebar-panel .mat-expansion-panel-content .mat-list-item {
  font-size: 12px;
  height: 40px;
}
.sidebar-panel .mat-expansion-panel-content .mat-list-item.open .mat-list-item-content {
  background-color: rgba(0, 0, 0, 0.0588);
  font-weight: 700;
}
.sidebar-panel .mat-expansion-panel-content .mat-expansion-panel-body {
  padding: 0 0 16px;
}
.sidebar-panel .mat-expansion-panel-content .mat-expansion-panel-body .mat-list-item-content {
  padding: 0 35px;
}
.sidebar-panel .mat-expansion-panel-spacing {
  margin: 0;
}
.sidebar-panel .mat-nav-list {
  padding-top: 0;
}
.sidebar-panel .mat-nav-list .mat-icon {
  margin-right: 24px;
}
.sidebar-panel .mat-nav-list span {
  font-size: 14px;
}

/* $chat-panel
 ------------------------------------------*/
.chat-panel {
  max-width: 320px;
  width: 100%;
  overflow: hidden;
}
.chat-panel mat-tab-group,
.chat-panel .mat-tab-body-wrapper,
.chat-panel mat-tab-body,
.chat-panel .mat-tab-body-content {
  height: 100%;
}
.chat-panel .scroll {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  width: 100%;
  -webkit-overflow-scrolling: touch;
}

@media (min-width: 600px) {
  .history-panel .mat-tab-body-content {
    min-height: 600px;
  }
}

/* $Widget
 ------------------------------------------*/
.ngx-datatable.material {
  padding: 0;
  margin: 0.3333333333rem;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  display: block;
  position: relative;
  border-radius: 2px;
  /**
   * Shared Styles
   */
  /**
   * Header Styles
   */
  /**
   * Body Styles
   */
  /**
   * Footer Styles
   */
}
.ngx-datatable.material.fullscreen {
  position: absolute !important;
  height: auto !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
}
.ngx-datatable.material.striped .datatable-row-odd {
  background: rgba(0, 0, 0, 0.04);
}
.ngx-datatable.material.single-selection .datatable-body-row.active, .ngx-datatable.material.single-selection .datatable-body-row.active .datatable-row-group, .ngx-datatable.material.multi-selection .datatable-body-row.active, .ngx-datatable.material.multi-selection .datatable-body-row.active .datatable-row-group {
  background: #007dbd;
  color: white;
}
.ngx-datatable.material.single-selection .datatable-body-row.active:hover, .ngx-datatable.material.single-selection .datatable-body-row.active:hover .datatable-row-group, .ngx-datatable.material.multi-selection .datatable-body-row.active:hover, .ngx-datatable.material.multi-selection .datatable-body-row.active:hover .datatable-row-group {
  background-color: #0076b3;
  color: #fff;
}
.ngx-datatable.material.single-selection .datatable-body-row.active:focus, .ngx-datatable.material.single-selection .datatable-body-row.active:focus .datatable-row-group, .ngx-datatable.material.multi-selection .datatable-body-row.active:focus, .ngx-datatable.material.multi-selection .datatable-body-row.active:focus .datatable-row-group {
  background-color: #0070a9;
  color: #fff;
}
.ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover, .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: #faffbd;
}
.ngx-datatable.material:not(.cell-selection) .datatable-body-row:focus, .ngx-datatable.material:not(.cell-selection) .datatable-body-row:focus .datatable-row-group {
  background-color: rgba(0, 0, 0, 0.04);
}
.ngx-datatable.material.cell-selection .datatable-body-cell:hover, .ngx-datatable.material.cell-selection .datatable-body-cell:hover .datatable-row-group {
  background: rgba(0, 0, 0, 0.04);
}
.ngx-datatable.material.cell-selection .datatable-body-cell:focus, .ngx-datatable.material.cell-selection .datatable-body-cell:focus .datatable-row-group {
  background-color: rgba(0, 0, 0, 0.04);
}
.ngx-datatable.material.cell-selection .datatable-body-cell.active, .ngx-datatable.material.cell-selection .datatable-body-cell.active .datatable-row-group {
  background: #007dbd;
  color: white;
}
.ngx-datatable.material.cell-selection .datatable-body-cell.active:hover, .ngx-datatable.material.cell-selection .datatable-body-cell.active:hover .datatable-row-group {
  background-color: #0076b3;
  color: #fff;
}
.ngx-datatable.material.cell-selection .datatable-body-cell.active:focus, .ngx-datatable.material.cell-selection .datatable-body-cell.active:focus .datatable-row-group {
  background-color: #0070a9;
  color: #fff;
}
.ngx-datatable.material .empty-row {
  padding: 0.85rem 1.2rem;
}
.ngx-datatable.material .loading-row {
  padding: 0.85rem 1.2rem;
}
.ngx-datatable.material .datatable-header {
  font-family: "Muli", "Helvetica Neue", Helvetica, sans-serif;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.ngx-datatable.material .datatable-header .datatable-header-cell {
  padding: 0.85rem 1.2rem;
  color: rgba(0, 0, 0, 0.87);
  vertical-align: middle;
}
.ngx-datatable.material .datatable-header .datatable-header-cell.longpress {
  color: #00e676;
}
.ngx-datatable.material .datatable-header .datatable-header-cell.dragging .resize-handle {
  border-right: none;
}
.ngx-datatable.material .datatable-header .resize-handle {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.ngx-datatable.material .datatable-body .datatable-row-detail {
  background: #f5f5f5;
  padding: 10px;
}
.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
  padding: 0.85rem 1.2rem;
  box-sizing: border-box;
  max-height: 100%;
  display: flex;
  flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  -webkit-box-pack: start;
  -webkit-box-align: center;
  -webkit-user-select: initial !important;
  -moz-user-select: initial !important;
  -ms-user-select: initial !important;
  -o-user-select: initial !important;
  user-select: initial !important;
}
.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell .datatable-body-cell-label {
  display: flex;
  width: 100%;
}
.ngx-datatable.material .datatable-body .progress-linear .container {
  background-color: #aad1f9;
}
.ngx-datatable.material .datatable-body .progress-linear .container .bar {
  background-color: #106cc8;
}
.ngx-datatable.material .datatable-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding: 0 15px;
}
.ngx-datatable.material .datatable-footer .datatable-pager li {
  vertical-align: middle;
}
.ngx-datatable.material .datatable-footer .datatable-pager li a {
  padding: 0 5px;
}
.ngx-datatable.material .datatable-footer .datatable-pager li.disabled a {
  color: rgba(0, 0, 0, 0.26) !important;
  background-color: transparent !important;
}
.ngx-datatable.material .datatable-footer .datatable-pager li.active a {
  background-color: #007dbd;
  color: white;
  font-weight: 400;
}
.ngx-datatable.material .datatable-footer .datatable-pager .pager {
  display: inline-flex;
}

.app-dark .ngx-datatable.material {
  background: #424242;
  color: white;
  /**
   * Header Styles
   */
  /**
   * Footer Styles
   */
}
.app-dark .ngx-datatable.material.striped .datatable-row-odd {
  background: rgba(255, 255, 255, 0.04);
}
.app-dark .ngx-datatable.material.single-selection .datatable-body-row.active, .app-dark .ngx-datatable.material.single-selection .datatable-body-row.active .datatable-row-group, .app-dark .ngx-datatable.material.multi-selection .datatable-body-row.active, .app-dark .ngx-datatable.material.multi-selection .datatable-body-row.active .datatable-row-group {
  background: #007dbd;
  color: white;
}
.app-dark .ngx-datatable.material.single-selection .datatable-body-row.active:hover, .app-dark .ngx-datatable.material.single-selection .datatable-body-row.active:hover .datatable-row-group, .app-dark .ngx-datatable.material.multi-selection .datatable-body-row.active:hover, .app-dark .ngx-datatable.material.multi-selection .datatable-body-row.active:hover .datatable-row-group {
  background-color: #0076b3;
}
.app-dark .ngx-datatable.material.single-selection .datatable-body-row.active:focus, .app-dark .ngx-datatable.material.single-selection .datatable-body-row.active:focus .datatable-row-group, .app-dark .ngx-datatable.material.multi-selection .datatable-body-row.active:focus, .app-dark .ngx-datatable.material.multi-selection .datatable-body-row.active:focus .datatable-row-group {
  background-color: #0070a9;
}
.app-dark .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover, .app-dark .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: rgba(255, 255, 255, 0.04);
}
.app-dark .ngx-datatable.material:not(.cell-selection) .datatable-body-row:focus, .app-dark .ngx-datatable.material:not(.cell-selection) .datatable-body-row:focus .datatable-row-group {
  background-color: rgba(250, 250, 250, 0.04);
}
.app-dark .ngx-datatable.material.cell-selection .datatable-body-cell:hover, .app-dark .ngx-datatable.material.cell-selection .datatable-body-cell:hover .datatable-row-group {
  background: rgba(255, 255, 255, 0.04);
}
.app-dark .ngx-datatable.material.cell-selection .datatable-body-cell:focus, .app-dark .ngx-datatable.material.cell-selection .datatable-body-cell:focus .datatable-row-group {
  background-color: rgba(250, 250, 250, 0.04);
}
.app-dark .ngx-datatable.material.cell-selection .datatable-body-cell.active, .app-dark .ngx-datatable.material.cell-selection .datatable-body-cell.active .datatable-row-group {
  background: #007dbd;
  color: white;
}
.app-dark .ngx-datatable.material.cell-selection .datatable-body-cell.active:hover, .app-dark .ngx-datatable.material.cell-selection .datatable-body-cell.active:hover .datatable-row-group {
  background-color: #0076b3;
}
.app-dark .ngx-datatable.material.cell-selection .datatable-body-cell.active:focus, .app-dark .ngx-datatable.material.cell-selection .datatable-body-cell.active:focus .datatable-row-group {
  background-color: #0070a9;
}
.app-dark .ngx-datatable.material .datatable-header .datatable-row-left,
.app-dark .ngx-datatable.material .datatable-body .datatable-row-left,
.app-dark .ngx-datatable.material .datatable-header .datatable-row-right,
.app-dark .ngx-datatable.material .datatable-body .datatable-row-right {
  background-color: #494949;
}
.app-dark .ngx-datatable.material .datatable-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.app-dark .ngx-datatable.material .datatable-header .datatable-header-cell {
  color: rgba(0, 0, 0, 0.87);
}
.app-dark .ngx-datatable.material .datatable-header .resize-handle {
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.app-dark .ngx-datatable.material .datatable-footer {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  padding: 0 15px;
}
.app-dark .ngx-datatable.material .datatable-footer .datatable-pager li.disabled a {
  color: rgba(255, 255, 255, 0.26) !important;
}
.app-dark .ngx-datatable.material .datatable-footer .datatable-pager li a {
  color: white;
}
.app-dark .ngx-datatable.material .datatable-footer .datatable-pager li.active a {
  background-color: #007dbd;
}

/* $error-pages
 ------------------------------------------*/
.session {
  position: relative;
  z-index: 4000;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.session-content {
  padding: 40px 1rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
  -moz-box-flex: 1;
  -moz-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 100%;
}

.session-wrapper {
  -webkit-box-flex: none;
  -webkit-flex: none;
  -moz-box-flex: none;
  -moz-flex: none;
  -ms-flex: none;
  flex: none;
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
}

.lockscreen-avatar {
  position: relative;
  display: block;
  margin: -75px auto 0 !important;
}

.lockscreen-wrapper {
  -webkit-box-flex: none;
  -webkit-flex: none;
  -moz-box-flex: none;
  -moz-flex: none;
  -ms-flex: none;
  flex: none;
  max-width: 280px;
  width: 100%;
  margin: 0 auto;
}

.error-title {
  font-size: 150px;
  line-height: 1.2;
  font-weight: 900;
  display: inline-table;
  position: relative;
  background: #007dbd;
  color: #fff;
  padding: 0 1rem;
  border-radius: 8px;
  cursor: pointer;
  margin: 0 0 1rem;
}
.error-title:after {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(0, 0, 0, 0);
  border-top-color: #007dbd;
  border-width: 8px;
  margin-left: -8px;
}

.error-subtitle {
  font-size: 32px;
  font-weight: 300;
}

[mat-raised-button],
[mat-fab],
[mat-mini-fab],
[mat-button],
[mat-icon-button] {
  border-radius: 2px;
  font-weight: 400;
}
[mat-raised-button][mat-button-sm],
[mat-fab][mat-button-sm],
[mat-mini-fab][mat-button-sm],
[mat-button][mat-button-sm],
[mat-icon-button][mat-button-sm] {
  font-size: 13px;
  line-height: 30px;
  padding: 0 8px;
}

[mat-button],
[mat-fab],
[mat-icon-button],
[mat-mini-fab],
[mat-raised-button] {
  font-family: "Roboto", "Helvetica Neue", Helvetica, sans-serif;
}

[mat-icon-button][mat-button-sm] {
  padding: 0;
  width: 30px;
  height: 30px;
}
[mat-icon-button][mat-button-sm] mat-icon {
  font-size: 20px;
  line-height: 20px;
}

[mat-fab].mat-fab-bottom-right {
  top: auto;
  right: 20px;
  bottom: 1rem;
  left: auto;
  position: fixed;
}

[mat-fab].mat-fab-bottom-left {
  top: auto;
  right: auto;
  bottom: -25px;
  left: 20px;
  position: fixed;
}

[mat-fab].mat-fab-top-right {
  top: 20px;
  right: 20px;
  bottom: auto;
  left: auto;
  position: fixed;
}

[mat-fab].mat-fab-top-left {
  top: 20px;
  right: auto;
  bottom: auto;
  left: 20px;
  position: fixed;
}

button.mat-green {
  color: #fff;
}

/* $cards
 ------------------------------------------*/
body mat-card {
  padding: 0;
  margin: 0.3333333333rem;
  border-radius: 2px;
  font-family: "Muli", "Helvetica Neue", Helvetica, sans-serif;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
}
body mat-card mat-card-header {
  height: auto;
}
body mat-card > :first-child {
  border-radius: 2px 2px 0 0;
}
body mat-card > :last-child {
  border-radius: 0 0 2px 2px;
}
body mat-card [mat-card-avatar] {
  font-size: 40px;
  line-height: 40px;
  margin: 1rem 0 0 1rem;
}
body mat-card [mat-card-image] {
  width: 100%;
  margin: 0;
}
body mat-card [mat-card-image]:first-child {
  margin-top: 0;
  border-radius: 2px 2px 0 0;
}
body mat-card mat-card-title {
  font-family: "Muli", "Helvetica Neue", Helvetica, sans-serif;
  padding-left: 1rem;
  padding-right: 1rem;
  line-height: 1;
  font-size: 16px;
  font-weight: 400;
}
body mat-card mat-card-subtitle {
  padding-left: 1rem;
  padding-right: 1rem;
  line-height: 1;
  font-size: 13px;
}
body mat-card mat-card-subtitle:first-child {
  padding-top: 1rem;
}
body mat-card mat-card-title:nth-child(2) {
  margin-top: -24px;
}
body mat-card mat-card-content {
  font-family: "Muli", "Helvetica Neue", Helvetica, sans-serif;
  padding: 1rem;
  margin-bottom: 0;
  position: relative;
}
body mat-card [mat-fab-card-float] {
  top: -36px;
  position: absolute;
  right: 8px;
}
body mat-card mat-card-actions:last-child, body mat-card .mat-card mat-card-actions:last-child {
  padding: 0.5rem;
  margin: 0;
}
body mat-card [mat-card-float-icon] {
  position: absolute;
  right: 15px;
  top: 50%;
  margin-top: -20px;
  width: 40px;
  height: 40px;
}
body mat-card [mat-card-float-icon] .material-icons {
  font-family: "Muli", "Helvetica Neue", Helvetica, sans-serif;
  font-size: 40px;
  opacity: 0.2;
  transform: rotate(-5deg);
}
body mat-card [mat-card-widget] {
  height: auto;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-grid-row-align: center;
  align-items: center;
  -webkit-align-content: center;
  align-content: center;
  max-width: 100%;
  padding: 1rem;
}
body mat-card [mat-card-widget] [mat-card-widget-title], body mat-card [mat-card-widget] p {
  margin: 0;
  padding: 0;
}
body mat-card .card-image-header {
  position: relative;
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repat;
  width: 100%;
}
body mat-card.card-widget .card-widget-content {
  display: flex;
  flex-direction: row;
  height: 40px;
  margin: -0.5rem 0 1rem 0;
}

body [dir=rtl] mat-card [mat-card-avatar] {
  margin: 1rem 1rem 0 0;
}
body [dir=rtl] mat-card [mat-fab-card-float] {
  right: auto;
  left: 8px;
}
body [dir=rtl] mat-card [mat-card-float-icon] {
  right: auto;
  left: 15px;
}

.mat-dialog-container {
  background: white;
  border-radius: 2px;
  margin: 0 auto;
  height: auto !important;
  position: relative;
}

.cdk-focus-trap-content {
  height: 100% !important;
}

/* $listgroup
 ------------------------------------------*/
mat-list mat-list-item, mat-list a[mat-list-item], mat-nav-list mat-list-item, mat-nav-list a[mat-list-item] {
  color: rgba(0, 0, 0, 0.87);
  font-family: "Muli", "Helvetica Neue", Helvetica, sans-serif;
}

mat-list-item mat-icon[mat-list-avatar], .mat-list-item mat-icon[mat-list-avatar] {
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: flex;
}

mat-divider {
  border-top: 1px solid rgba(0, 0, 0, 0.12) !important;
}

mat-list mat-list-item [mat-list-avatar], mat-list a[mat-list-item] [mat-list-avatar], mat-nav-list mat-list-item [mat-list-avatar], mat-nav-list a[mat-list-item] [mat-list-avatar] {
  min-width: 40px;
  font-family: "Muli", "Helvetica Neue", Helvetica, sans-serif;
}

mat-list-item.active > .mat-list-item {
  background: rgba(0, 0, 0, 0.04);
}

mat-list mat-list-item .mat-list-item,
mat-list a[mat-list-item] .mat-list-item,
mat-nav-list mat-list-item .mat-list-item,
mat-nav-list a[mat-list-item] .mat-list-item {
  font-size: 0.875rem !important;
}

.mat-select-panel {
  background-color: white;
}

mat-option {
  font-size: 0.875rem !important;
  font-family: "Muli", "Helvetica Neue", Helvetica, sans-serif;
}

.app-dark .mat-select-panel {
  background-color: #424242;
}

.mat-menu {
  border-radius: 2px;
}

[mat-menu-item] {
  font-size: 0.875rem !important;
  font-family: "Muli", "Helvetica Neue", Helvetica, sans-serif;
}

.mat-tab-label {
  color: rgba(0, 0, 0, 0.87);
  align-items: center;
  display: flex;
  justify-content: center;
  font-family: "Muli", "Helvetica Neue", Helvetica, sans-serif;
}

.app-dark .mat-tab-label {
  color: white;
}

.mat-red {
  background-color: #f44336;
  color: white;
}

.mat-red-50 {
  background-color: #ffebee;
  color: rgba(0, 0, 0, 0.87);
}

.mat-red-100 {
  background-color: #ffcdd2;
  color: rgba(0, 0, 0, 0.87);
}

.mat-red-200 {
  background-color: #ef9a9a;
  color: rgba(0, 0, 0, 0.87);
}

.mat-red-300 {
  background-color: #e57373;
  color: rgba(0, 0, 0, 0.87);
}

.mat-red-400 {
  background-color: #ef5350;
  color: rgba(0, 0, 0, 0.87);
}

.mat-red-500 {
  background-color: #f44336;
  color: white;
}

.mat-red-600 {
  background-color: #e53935;
  color: white;
}

.mat-red-700 {
  background-color: #d32f2f;
  color: white;
}

.mat-red-800 {
  background-color: #c62828;
  color: white;
}

.mat-red-900 {
  background-color: #b71c1c;
  color: white;
}

.mat-red-A100 {
  background-color: #ff8a80;
  color: rgba(0, 0, 0, 0.87);
}

.mat-red-A200 {
  background-color: #ff5252;
  color: white;
}

.mat-red-A400 {
  background-color: #ff1744;
  color: white;
}

.mat-red-A700 {
  background-color: #d50000;
  color: white;
}

.mat-pink {
  background-color: #e91e63;
  color: white;
}

.mat-pink-50 {
  background-color: #fce4ec;
  color: rgba(0, 0, 0, 0.87);
}

.mat-pink-100 {
  background-color: #f8bbd0;
  color: rgba(0, 0, 0, 0.87);
}

.mat-pink-200 {
  background-color: #f48fb1;
  color: rgba(0, 0, 0, 0.87);
}

.mat-pink-300 {
  background-color: #f06292;
  color: rgba(0, 0, 0, 0.87);
}

.mat-pink-400 {
  background-color: #ec407a;
  color: rgba(0, 0, 0, 0.87);
}

.mat-pink-500 {
  background-color: #e91e63;
  color: white;
}

.mat-pink-600 {
  background-color: #d81b60;
  color: white;
}

.mat-pink-700 {
  background-color: #c2185b;
  color: white;
}

.mat-pink-800 {
  background-color: #ad1457;
  color: white;
}

.mat-pink-900 {
  background-color: #880e4f;
  color: white;
}

.mat-pink-A100 {
  background-color: #ff80ab;
  color: rgba(0, 0, 0, 0.87);
}

.mat-pink-A200 {
  background-color: #ff4081;
  color: white;
}

.mat-pink-A400 {
  background-color: #f50057;
  color: white;
}

.mat-pink-A700 {
  background-color: #c51162;
  color: white;
}

.mat-purple {
  background-color: #9c27b0;
  color: white;
}

.mat-purple-50 {
  background-color: #f3e5f5;
  color: rgba(0, 0, 0, 0.87);
}

.mat-purple-100 {
  background-color: #e1bee7;
  color: rgba(0, 0, 0, 0.87);
}

.mat-purple-200 {
  background-color: #ce93d8;
  color: rgba(0, 0, 0, 0.87);
}

.mat-purple-300 {
  background-color: #ba68c8;
  color: white;
}

.mat-purple-400 {
  background-color: #ab47bc;
  color: white;
}

.mat-purple-500 {
  background-color: #9c27b0;
  color: white;
}

.mat-purple-600 {
  background-color: #8e24aa;
  color: white;
}

.mat-purple-700 {
  background-color: #7b1fa2;
  color: white;
}

.mat-purple-800 {
  background-color: #6a1b9a;
  color: white;
}

.mat-purple-900 {
  background-color: #4a148c;
  color: white;
}

.mat-purple-A100 {
  background-color: #ea80fc;
  color: rgba(0, 0, 0, 0.87);
}

.mat-purple-A200 {
  background-color: #e040fb;
  color: white;
}

.mat-purple-A400 {
  background-color: #d500f9;
  color: white;
}

.mat-purple-A700 {
  background-color: #aa00ff;
  color: white;
}

.mat-deep-purple {
  background-color: #673ab7;
  color: white;
}

.mat-deep-purple-50 {
  background-color: #ede7f6;
  color: rgba(0, 0, 0, 0.87);
}

.mat-deep-purple-100 {
  background-color: #d1c4e9;
  color: rgba(0, 0, 0, 0.87);
}

.mat-deep-purple-200 {
  background-color: #b39ddb;
  color: rgba(0, 0, 0, 0.87);
}

.mat-deep-purple-300 {
  background-color: #9575cd;
  color: white;
}

.mat-deep-purple-400 {
  background-color: #7e57c2;
  color: white;
}

.mat-deep-purple-500 {
  background-color: #673ab7;
  color: white;
}

.mat-deep-purple-600 {
  background-color: #5e35b1;
  color: white;
}

.mat-deep-purple-700 {
  background-color: #512da8;
  color: white;
}

.mat-deep-purple-800 {
  background-color: #4527a0;
  color: white;
}

.mat-deep-purple-900 {
  background-color: #311b92;
  color: white;
}

.mat-deep-purple-A100 {
  background-color: #b388ff;
  color: rgba(0, 0, 0, 0.87);
}

.mat-deep-purple-A200 {
  background-color: #7c4dff;
  color: white;
}

.mat-deep-purple-A400 {
  background-color: #651fff;
  color: white;
}

.mat-deep-purple-A700 {
  background-color: #6200ea;
  color: white;
}

.mat-indigo {
  background-color: #3f51b5;
  color: white;
}

.mat-indigo-50 {
  background-color: #e8eaf6;
  color: rgba(0, 0, 0, 0.87);
}

.mat-indigo-100 {
  background-color: #c5cae9;
  color: rgba(0, 0, 0, 0.87);
}

.mat-indigo-200 {
  background-color: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}

.mat-indigo-300 {
  background-color: #7986cb;
  color: white;
}

.mat-indigo-400 {
  background-color: #5c6bc0;
  color: white;
}

.mat-indigo-500 {
  background-color: #3f51b5;
  color: white;
}

.mat-indigo-600 {
  background-color: #3949ab;
  color: white;
}

.mat-indigo-700 {
  background-color: #303f9f;
  color: white;
}

.mat-indigo-800 {
  background-color: #283593;
  color: white;
}

.mat-indigo-900 {
  background-color: #1a237e;
  color: white;
}

.mat-indigo-A100 {
  background-color: #8c9eff;
  color: rgba(0, 0, 0, 0.87);
}

.mat-indigo-A200 {
  background-color: #536dfe;
  color: white;
}

.mat-indigo-A400 {
  background-color: #3d5afe;
  color: white;
}

.mat-indigo-A700 {
  background-color: #304ffe;
  color: white;
}

.mat-mcs-blue {
  background-color: #007dbd;
  color: white;
}

.mat-blue {
  background-color: #2196f3;
  color: white;
}

.mat-blue-50 {
  background-color: #e3f2fd;
  color: rgba(0, 0, 0, 0.87);
}

.mat-blue-100 {
  background-color: #bbdefb;
  color: rgba(0, 0, 0, 0.87);
}

.mat-blue-200 {
  background-color: #90caf9;
  color: rgba(0, 0, 0, 0.87);
}

.mat-blue-300 {
  background-color: #64b5f6;
  color: rgba(0, 0, 0, 0.87);
}

.mat-blue-400 {
  background-color: #42a5f5;
  color: rgba(0, 0, 0, 0.87);
}

.mat-blue-500 {
  background-color: #2196f3;
  color: white;
}

.mat-blue-600 {
  background-color: #1e88e5;
  color: white;
}

.mat-blue-700 {
  background-color: #1976d2;
  color: white;
}

.mat-blue-800 {
  background-color: #1565c0;
  color: white;
}

.mat-blue-900 {
  background-color: #0d47a1;
  color: white;
}

.mat-blue-A100 {
  background-color: #82b1ff;
  color: rgba(0, 0, 0, 0.87);
}

.mat-blue-A200 {
  background-color: #448aff;
  color: white;
}

.mat-blue-A400 {
  background-color: #2979ff;
  color: white;
}

.mat-blue-A700 {
  background-color: #2962ff;
  color: white;
}

.mat-light-blue {
  background-color: #03a9f4;
  color: white;
}

.mat-light-blue-50 {
  background-color: #e1f5fe;
  color: rgba(0, 0, 0, 0.87);
}

.mat-light-blue-100 {
  background-color: #b3e5fc;
  color: rgba(0, 0, 0, 0.87);
}

.mat-light-blue-200 {
  background-color: #81d4fa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-light-blue-300 {
  background-color: #4fc3f7;
  color: rgba(0, 0, 0, 0.87);
}

.mat-light-blue-400 {
  background-color: #29b6f6;
  color: rgba(0, 0, 0, 0.87);
}

.mat-light-blue-500 {
  background-color: #03a9f4;
  color: white;
}

.mat-light-blue-600 {
  background-color: #039be5;
  color: white;
}

.mat-light-blue-700 {
  background-color: #0288d1;
  color: white;
}

.mat-light-blue-800 {
  background-color: #0277bd;
  color: white;
}

.mat-light-blue-900 {
  background-color: #01579b;
  color: white;
}

.mat-light-blue-A100 {
  background-color: #80d8ff;
  color: rgba(0, 0, 0, 0.87);
}

.mat-light-blue-A200 {
  background-color: #40c4ff;
  color: rgba(0, 0, 0, 0.87);
}

.mat-light-blue-A400 {
  background-color: #00b0ff;
  color: rgba(0, 0, 0, 0.87);
}

.mat-light-blue-A700 {
  background-color: #0091ea;
  color: white;
}

.mat-cyan {
  background-color: #00bcd4;
  color: white;
}

.mat-cyan-50 {
  background-color: #e0f7fa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-cyan-100 {
  background-color: #b2ebf2;
  color: rgba(0, 0, 0, 0.87);
}

.mat-cyan-200 {
  background-color: #80deea;
  color: rgba(0, 0, 0, 0.87);
}

.mat-cyan-300 {
  background-color: #4dd0e1;
  color: rgba(0, 0, 0, 0.87);
}

.mat-cyan-400 {
  background-color: #26c6da;
  color: rgba(0, 0, 0, 0.87);
}

.mat-cyan-500 {
  background-color: #00bcd4;
  color: white;
}

.mat-cyan-600 {
  background-color: #00acc1;
  color: white;
}

.mat-cyan-700 {
  background-color: #0097a7;
  color: white;
}

.mat-cyan-800 {
  background-color: #00838f;
  color: white;
}

.mat-cyan-900 {
  background-color: #006064;
  color: white;
}

.mat-cyan-A100 {
  background-color: #84ffff;
  color: rgba(0, 0, 0, 0.87);
}

.mat-cyan-A200 {
  background-color: #18ffff;
  color: rgba(0, 0, 0, 0.87);
}

.mat-cyan-A400 {
  background-color: #00e5ff;
  color: rgba(0, 0, 0, 0.87);
}

.mat-cyan-A700 {
  background-color: #00b8d4;
  color: rgba(0, 0, 0, 0.87);
}

.mat-teal {
  background-color: #009688;
  color: white;
}

.mat-teal-50 {
  background-color: #e0f2f1;
  color: rgba(0, 0, 0, 0.87);
}

.mat-teal-100 {
  background-color: #b2dfdb;
  color: rgba(0, 0, 0, 0.87);
}

.mat-teal-200 {
  background-color: #80cbc4;
  color: rgba(0, 0, 0, 0.87);
}

.mat-teal-300 {
  background-color: #4db6ac;
  color: rgba(0, 0, 0, 0.87);
}

.mat-teal-400 {
  background-color: #26a69a;
  color: rgba(0, 0, 0, 0.87);
}

.mat-teal-500 {
  background-color: #009688;
  color: white;
}

.mat-teal-600 {
  background-color: #00897b;
  color: white;
}

.mat-teal-700 {
  background-color: #00796b;
  color: white;
}

.mat-teal-800 {
  background-color: #00695c;
  color: white;
}

.mat-teal-900 {
  background-color: #004d40;
  color: white;
}

.mat-teal-A100 {
  background-color: #a7ffeb;
  color: rgba(0, 0, 0, 0.87);
}

.mat-teal-A200 {
  background-color: #64ffda;
  color: rgba(0, 0, 0, 0.87);
}

.mat-teal-A400 {
  background-color: #1de9b6;
  color: rgba(0, 0, 0, 0.87);
}

.mat-teal-A700 {
  background-color: #00bfa5;
  color: rgba(0, 0, 0, 0.87);
}

.mat-green {
  background-color: #4caf50;
  color: rgba(0, 0, 0, 0.87);
}

.mat-green-50 {
  background-color: #e8f5e9;
  color: rgba(0, 0, 0, 0.87);
}

.mat-green-100 {
  background-color: #c8e6c9;
  color: rgba(0, 0, 0, 0.87);
}

.mat-green-200 {
  background-color: #a5d6a7;
  color: rgba(0, 0, 0, 0.87);
}

.mat-green-300 {
  background-color: #81c784;
  color: rgba(0, 0, 0, 0.87);
}

.mat-green-400 {
  background-color: #66bb6a;
  color: rgba(0, 0, 0, 0.87);
}

.mat-green-500 {
  background-color: #4caf50;
  color: rgba(0, 0, 0, 0.87);
}

.mat-green-600 {
  background-color: #43a047;
  color: white;
}

.mat-green-700 {
  background-color: #388e3c;
  color: white;
}

.mat-green-800 {
  background-color: #2e7d32;
  color: white;
}

.mat-green-900 {
  background-color: #1b5e20;
  color: white;
}

.mat-green-A100 {
  background-color: #b9f6ca;
  color: rgba(0, 0, 0, 0.87);
}

.mat-green-A200 {
  background-color: #69f0ae;
  color: rgba(0, 0, 0, 0.87);
}

.mat-green-A400 {
  background-color: #00e676;
  color: rgba(0, 0, 0, 0.87);
}

.mat-green-A700 {
  background-color: #00c853;
  color: rgba(0, 0, 0, 0.87);
}

.mat-light-green {
  background-color: #8bc34a;
  color: rgba(0, 0, 0, 0.87);
}

.mat-light-green-50 {
  background-color: #f1f8e9;
  color: rgba(0, 0, 0, 0.87);
}

.mat-light-green-100 {
  background-color: #dcedc8;
  color: rgba(0, 0, 0, 0.87);
}

.mat-light-green-200 {
  background-color: #c5e1a5;
  color: rgba(0, 0, 0, 0.87);
}

.mat-light-green-300 {
  background-color: #aed581;
  color: rgba(0, 0, 0, 0.87);
}

.mat-light-green-400 {
  background-color: #9ccc65;
  color: rgba(0, 0, 0, 0.87);
}

.mat-light-green-500 {
  background-color: #8bc34a;
  color: rgba(0, 0, 0, 0.87);
}

.mat-light-green-600 {
  background-color: #7cb342;
  color: rgba(0, 0, 0, 0.87);
}

.mat-light-green-700 {
  background-color: #689f38;
  color: white;
}

.mat-light-green-800 {
  background-color: #558b2f;
  color: white;
}

.mat-light-green-900 {
  background-color: #33691e;
  color: white;
}

.mat-light-green-A100 {
  background-color: #ccff90;
  color: rgba(0, 0, 0, 0.87);
}

.mat-light-green-A200 {
  background-color: #b2ff59;
  color: rgba(0, 0, 0, 0.87);
}

.mat-light-green-A400 {
  background-color: #76ff03;
  color: rgba(0, 0, 0, 0.87);
}

.mat-light-green-A700 {
  background-color: #64dd17;
  color: rgba(0, 0, 0, 0.87);
}

.mat-lime {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}

.mat-lime-50 {
  background-color: #f9fbe7;
  color: rgba(0, 0, 0, 0.87);
}

.mat-lime-100 {
  background-color: #f0f4c3;
  color: rgba(0, 0, 0, 0.87);
}

.mat-lime-200 {
  background-color: #e6ee9c;
  color: rgba(0, 0, 0, 0.87);
}

.mat-lime-300 {
  background-color: #dce775;
  color: rgba(0, 0, 0, 0.87);
}

.mat-lime-400 {
  background-color: #d4e157;
  color: rgba(0, 0, 0, 0.87);
}

.mat-lime-500 {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}

.mat-lime-600 {
  background-color: #c0ca33;
  color: rgba(0, 0, 0, 0.87);
}

.mat-lime-700 {
  background-color: #afb42b;
  color: rgba(0, 0, 0, 0.87);
}

.mat-lime-800 {
  background-color: #9e9d24;
  color: rgba(0, 0, 0, 0.87);
}

.mat-lime-900 {
  background-color: #827717;
  color: white;
}

.mat-lime-A100 {
  background-color: #f4ff81;
  color: rgba(0, 0, 0, 0.87);
}

.mat-lime-A200 {
  background-color: #eeff41;
  color: rgba(0, 0, 0, 0.87);
}

.mat-lime-A400 {
  background-color: #c6ff00;
  color: rgba(0, 0, 0, 0.87);
}

.mat-lime-A700 {
  background-color: #aeea00;
  color: rgba(0, 0, 0, 0.87);
}

.mat-yellow {
  background-color: #ffeb3b;
  color: rgba(0, 0, 0, 0.87);
}

.mat-yellow-50 {
  background-color: #fffde7;
  color: rgba(0, 0, 0, 0.87);
}

.mat-yellow-100 {
  background-color: #fff9c4;
  color: rgba(0, 0, 0, 0.87);
}

.mat-yellow-200 {
  background-color: #fff59d;
  color: rgba(0, 0, 0, 0.87);
}

.mat-yellow-300 {
  background-color: #fff176;
  color: rgba(0, 0, 0, 0.87);
}

.mat-yellow-400 {
  background-color: #ffee58;
  color: rgba(0, 0, 0, 0.87);
}

.mat-yellow-500 {
  background-color: #ffeb3b;
  color: rgba(0, 0, 0, 0.87);
}

.mat-yellow-600 {
  background-color: #fdd835;
  color: rgba(0, 0, 0, 0.87);
}

.mat-yellow-700 {
  background-color: #fbc02d;
  color: rgba(0, 0, 0, 0.87);
}

.mat-yellow-800 {
  background-color: #f9a825;
  color: rgba(0, 0, 0, 0.87);
}

.mat-yellow-900 {
  background-color: #f57f17;
  color: rgba(0, 0, 0, 0.87);
}

.mat-yellow-A100 {
  background-color: #ffff8d;
  color: rgba(0, 0, 0, 0.87);
}

.mat-yellow-A200 {
  background-color: yellow;
  color: rgba(0, 0, 0, 0.87);
}

.mat-yellow-A400 {
  background-color: #ffea00;
  color: rgba(0, 0, 0, 0.87);
}

.mat-yellow-A700 {
  background-color: #ffd600;
  color: rgba(0, 0, 0, 0.87);
}

.mat-amber {
  background-color: #ffc107;
  color: rgba(0, 0, 0, 0.87);
}

.mat-amber-50 {
  background-color: #fff8e1;
  color: rgba(0, 0, 0, 0.87);
}

.mat-amber-100 {
  background-color: #ffecb3;
  color: rgba(0, 0, 0, 0.87);
}

.mat-amber-200 {
  background-color: #ffe082;
  color: rgba(0, 0, 0, 0.87);
}

.mat-amber-300 {
  background-color: #ffd54f;
  color: rgba(0, 0, 0, 0.87);
}

.mat-amber-400 {
  background-color: #ffca28;
  color: rgba(0, 0, 0, 0.87);
}

.mat-amber-500 {
  background-color: #ffc107;
  color: rgba(0, 0, 0, 0.87);
}

.mat-amber-600 {
  background-color: #ffb300;
  color: rgba(0, 0, 0, 0.87);
}

.mat-amber-700 {
  background-color: #ffa000;
  color: rgba(0, 0, 0, 0.87);
}

.mat-amber-800 {
  background-color: #ff8f00;
  color: rgba(0, 0, 0, 0.87);
}

.mat-amber-900 {
  background-color: #ff6f00;
  color: rgba(0, 0, 0, 0.87);
}

.mat-amber-A100 {
  background-color: #ffe57f;
  color: rgba(0, 0, 0, 0.87);
}

.mat-amber-A200 {
  background-color: #ffd740;
  color: rgba(0, 0, 0, 0.87);
}

.mat-amber-A400 {
  background-color: #ffc400;
  color: rgba(0, 0, 0, 0.87);
}

.mat-amber-A700 {
  background-color: #ffab00;
  color: rgba(0, 0, 0, 0.87);
}

.mat-orange {
  background-color: #ff9800;
  color: rgba(0, 0, 0, 0.87);
}

.mat-orange-50 {
  background-color: #fff3e0;
  color: rgba(0, 0, 0, 0.87);
}

.mat-orange-100 {
  background-color: #ffe0b2;
  color: rgba(0, 0, 0, 0.87);
}

.mat-orange-200 {
  background-color: #ffcc80;
  color: rgba(0, 0, 0, 0.87);
}

.mat-orange-300 {
  background-color: #ffb74d;
  color: rgba(0, 0, 0, 0.87);
}

.mat-orange-400 {
  background-color: #ffa726;
  color: rgba(0, 0, 0, 0.87);
}

.mat-orange-500 {
  background-color: #ff9800;
  color: rgba(0, 0, 0, 0.87);
}

.mat-orange-600 {
  background-color: #fb8c00;
  color: rgba(0, 0, 0, 0.87);
}

.mat-orange-700 {
  background-color: #f57c00;
  color: rgba(0, 0, 0, 0.87);
}

.mat-orange-800 {
  background-color: #ef6c00;
  color: white;
}

.mat-orange-900 {
  background-color: #e65100;
  color: white;
}

.mat-orange-A100 {
  background-color: #ffd180;
  color: rgba(0, 0, 0, 0.87);
}

.mat-orange-A200 {
  background-color: #ffab40;
  color: rgba(0, 0, 0, 0.87);
}

.mat-orange-A400 {
  background-color: #ff9100;
  color: rgba(0, 0, 0, 0.87);
}

.mat-orange-A700 {
  background-color: #ff6d00;
  color: black;
}

.mat-deep-orange {
  background-color: #ff5722;
  color: white;
}

.mat-deep-orange-50 {
  background-color: #fbe9e7;
  color: rgba(0, 0, 0, 0.87);
}

.mat-deep-orange-100 {
  background-color: #ffccbc;
  color: rgba(0, 0, 0, 0.87);
}

.mat-deep-orange-200 {
  background-color: #ffab91;
  color: rgba(0, 0, 0, 0.87);
}

.mat-deep-orange-300 {
  background-color: #ff8a65;
  color: rgba(0, 0, 0, 0.87);
}

.mat-deep-orange-400 {
  background-color: #ff7043;
  color: rgba(0, 0, 0, 0.87);
}

.mat-deep-orange-500 {
  background-color: #ff5722;
  color: white;
}

.mat-deep-orange-600 {
  background-color: #f4511e;
  color: white;
}

.mat-deep-orange-700 {
  background-color: #e64a19;
  color: white;
}

.mat-deep-orange-800 {
  background-color: #d84315;
  color: white;
}

.mat-deep-orange-900 {
  background-color: #bf360c;
  color: white;
}

.mat-deep-orange-A100 {
  background-color: #ff9e80;
  color: rgba(0, 0, 0, 0.87);
}

.mat-deep-orange-A200 {
  background-color: #ff6e40;
  color: rgba(0, 0, 0, 0.87);
}

.mat-deep-orange-A400 {
  background-color: #ff3d00;
  color: white;
}

.mat-deep-orange-A700 {
  background-color: #dd2c00;
  color: white;
}

.mat-brown {
  background-color: #795548;
  color: white;
}

.mat-brown-50 {
  background-color: #efebe9;
  color: rgba(0, 0, 0, 0.87);
}

.mat-brown-100 {
  background-color: #d7ccc8;
  color: rgba(0, 0, 0, 0.87);
}

.mat-brown-200 {
  background-color: #bcaaa4;
  color: rgba(0, 0, 0, 0.87);
}

.mat-brown-300 {
  background-color: #a1887f;
  color: white;
}

.mat-brown-400 {
  background-color: #8d6e63;
  color: white;
}

.mat-brown-500 {
  background-color: #795548;
  color: white;
}

.mat-brown-600 {
  background-color: #6d4c41;
  color: white;
}

.mat-brown-700 {
  background-color: #5d4037;
  color: white;
}

.mat-brown-800 {
  background-color: #4e342e;
  color: white;
}

.mat-brown-900 {
  background-color: #3e2723;
  color: white;
}

.mat-brown-A100 {
  background-color: #d7ccc8;
  color: rgba(0, 0, 0, 0.87);
}

.mat-brown-A200 {
  background-color: #bcaaa4;
  color: rgba(0, 0, 0, 0.87);
}

.mat-brown-A400 {
  background-color: #8d6e63;
  color: white;
}

.mat-brown-A700 {
  background-color: #5d4037;
  color: white;
}

.mat-grey {
  background-color: #9e9e9e;
  color: rgba(0, 0, 0, 0.87);
}

.mat-grey-50 {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-grey-100 {
  background-color: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}

.mat-grey-200 {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.87);
}

.mat-grey-300 {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}

.mat-grey-400 {
  background-color: #bdbdbd;
  color: rgba(0, 0, 0, 0.87);
}

.mat-grey-500 {
  background-color: #9e9e9e;
  color: rgba(0, 0, 0, 0.87);
}

.mat-grey-600 {
  background-color: #757575;
  color: white;
}

.mat-grey-700 {
  background-color: #616161;
  color: white;
}

.mat-grey-800 {
  background-color: #424242;
  color: white;
}

.mat-grey-900 {
  background-color: #212121;
  color: white;
}

.mat-grey-A100 {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-grey-A200 {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.87);
}

.mat-grey-A400 {
  background-color: #bdbdbd;
  color: rgba(0, 0, 0, 0.87);
}

.mat-grey-A700 {
  background-color: #616161;
  color: white;
}

.mat-blue-grey {
  background-color: #607d8b;
  color: white;
}

.mat-blue-grey-50 {
  background-color: #eceff1;
  color: rgba(0, 0, 0, 0.87);
}

.mat-blue-grey-100 {
  background-color: #cfd8dc;
  color: rgba(0, 0, 0, 0.87);
}

.mat-blue-grey-200 {
  background-color: #b0bec5;
  color: rgba(0, 0, 0, 0.87);
}

.mat-blue-grey-300 {
  background-color: #90a4ae;
  color: rgba(0, 0, 0, 0.87);
}

.mat-blue-grey-400 {
  background-color: #78909c;
  color: white;
}

.mat-blue-grey-500 {
  background-color: #607d8b;
  color: white;
}

.mat-blue-grey-600 {
  background-color: #546e7a;
  color: white;
}

.mat-blue-grey-700 {
  background-color: #455a64;
  color: white;
}

.mat-blue-grey-800 {
  background-color: #37474f;
  color: white;
}

.mat-blue-grey-900 {
  background-color: #263238;
  color: white;
}

.mat-blue-grey-A100 {
  background-color: #cfd8dc;
  color: rgba(0, 0, 0, 0.87);
}

.mat-blue-grey-A200 {
  background-color: #b0bec5;
  color: rgba(0, 0, 0, 0.87);
}

.mat-blue-grey-A400 {
  background-color: #78909c;
  color: white;
}

.mat-blue-grey-A700 {
  background-color: #455a64;
  color: white;
}

.radius-none {
  border-radius: 0;
}

.radius-round {
  border-radius: 2px;
}

.radius-circle {
  border-radius: 50%;
}

.overflow-visible {
  overflow: visible;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-auto {
  overflow: auto;
}

.block {
  display: block;
}

.button-block {
  display: block;
  width: 100%;
}

.inline-block {
  display: inline-block;
}

.center-block {
  display: block;
  margin: 0 auto;
}

.relative {
  position: relative;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.shadow-none {
  box-shadow: none !important;
}

.border-none {
  border: 0 !important;
}

.background-none {
  background-color: transparent !important;
}

.mat-tooltip {
  height: auto !important;
}

.ma-0 {
  margin: 0 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.ma-xs {
  margin: 0.5555555556rem 0.5555555556rem !important;
}

.mt-xs {
  margin-top: 0.5555555556rem !important;
}

.mr-xs {
  margin-right: 0.5555555556rem !important;
}

.mb-xs {
  margin-bottom: 0.5555555556rem !important;
}

.ml-xs {
  margin-left: 0.5555555556rem !important;
}

.mx-xs {
  margin-right: 0.5555555556rem !important;
  margin-left: 0.5555555556rem !important;
}

.my-xs {
  margin-top: 0.5555555556rem !important;
  margin-bottom: 0.5555555556rem !important;
}

.ma-1 {
  margin: 1rem 1rem !important;
}

.mt-1 {
  margin-top: 1rem !important;
}

.mr-1 {
  margin-right: 1rem !important;
}

.mb-1 {
  margin-bottom: 1rem !important;
}

.ml-1 {
  margin-left: 1rem !important;
}

.mx-1 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.my-1 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.ma-2 {
  margin: 1.5rem 1.5rem !important;
}

.mt-2 {
  margin-top: 1.5rem !important;
}

.mr-2 {
  margin-right: 1.5rem !important;
}

.mb-2 {
  margin-bottom: 1.5rem !important;
}

.ml-2 {
  margin-left: 1.5rem !important;
}

.mx-2 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.my-2 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.ma-3 {
  margin: 3rem 3rem !important;
}

.mt-3 {
  margin-top: 3rem !important;
}

.mr-3 {
  margin-right: 3rem !important;
}

.mb-3 {
  margin-bottom: 3rem !important;
}

.ml-3 {
  margin-left: 3rem !important;
}

.mx-3 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.my-3 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.pa-0 {
  padding: 0 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.pa-xs {
  padding: 0.5555555556rem 0.5555555556rem !important;
}

.pt-xs {
  padding-top: 0.5555555556rem !important;
}

.pr-xs {
  padding-right: 0.5555555556rem !important;
}

.pb-xs {
  padding-bottom: 0.5555555556rem !important;
}

.pl-xs {
  padding-left: 0.5555555556rem !important;
}

.px-xs {
  padding-right: 0.5555555556rem !important;
  padding-left: 0.5555555556rem !important;
}

.py-xs {
  padding-top: 0.5555555556rem !important;
  padding-bottom: 0.5555555556rem !important;
}

.pa-1 {
  padding: 1rem 1rem !important;
}

.pt-1 {
  padding-top: 1rem !important;
}

.pr-1 {
  padding-right: 1rem !important;
}

.pb-1 {
  padding-bottom: 1rem !important;
}

.pl-1 {
  padding-left: 1rem !important;
}

.px-1 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.py-1 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.pa-2 {
  padding: 1.5rem 1.5rem !important;
}

.pt-2 {
  padding-top: 1.5rem !important;
}

.pr-2 {
  padding-right: 1.5rem !important;
}

.pb-2 {
  padding-bottom: 1.5rem !important;
}

.pl-2 {
  padding-left: 1.5rem !important;
}

.px-2 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.py-2 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.pa-3 {
  padding: 3rem 3rem !important;
}

.pt-3 {
  padding-top: 3rem !important;
}

.pr-3 {
  padding-right: 3rem !important;
}

.pb-3 {
  padding-bottom: 3rem !important;
}

.pl-3 {
  padding-left: 3rem !important;
}

.px-3 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-3 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-xs-left {
  text-align: left !important;
}

.text-xs-right {
  text-align: right !important;
}

.text-xs-center {
  text-align: center !important;
}

@media (min-width: 600px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 960px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 1280px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1920px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-xs {
  font-size: 50% !important;
}

.text-sm {
  font-size: 70% !important;
}

.text-md {
  font-size: 80% !important;
}

.text-df {
  font-size: 13px !important;
}

.text-lg {
  font-size: 110% !important;
}

.text-xl {
  font-size: 120% !important;
}

.text-xxl {
  font-size: 60px !important;
}

.font-weight-normal {
  font-weight: normal !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-italic {
  font-style: italic !important;
}

.mat-text-muted {
  color: rgba(0, 0, 0, 0.54) !important;
}

a.mat-text-muted:focus, a.mat-text-muted:hover {
  color: rgba(0, 0, 0, 0.54);
}

.mat-text-default {
  color: black !important;
}

a.mat-text-default:focus, a.mat-text-default:hover {
  color: black;
}

.mat-text-primary {
  color: #007dbd !important;
}

a.mat-text-primary:focus, a.mat-text-primary:hover {
  color: #005b8a;
}

.mat-text-warn {
  color: #f44336 !important;
}

a.mat-text-warn:focus, a.mat-text-warn:hover {
  color: #ea1c0d;
}

.mat-text-accent {
  color: #ffd740 !important;
}

a.mat-text-accent:focus, a.mat-text-accent:hover {
  color: #ffcc0d;
}

.app-dark .mat-text-muted {
  color: rgba(255, 255, 255, 0.7) !important;
}
.app-dark a.mat-text-muted:focus, .app-dark a.mat-text-muted:hover {
  color: rgba(230, 230, 230, 0.7);
}
.app-dark .mat-text-default {
  color: white !important;
}
.app-dark a.mat-text-default:focus, .app-dark a.mat-text-default:hover {
  color: #e6e6e6;
}