/* $header
 ------------------------------------------*/
mat-toolbar {
  .toolbar-avatar {
    width: 40px;
    height: 40px;
    line-height: 24px;
  }

  .toolbar-avatar img {
    width: 40px;
    border-radius: 50%;
  }

  .notification-label {
    position: absolute;
    top: 0;
    left: 50%;
    font-size: 12px;
    font-weight: 700;
    line-height: 13px;
    border-radius: 50%;
    width: 13px;
    height: 13px;
    background-color: mat-color($warn);
    border: 4px solid mat-color($warn);
    color: white;
    text-align: center;
  }

  &.main-header {
    padding: 0 8px;
    position: relative;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    z-index: 1;

    .branding {
      display: block;
      white-space: nowrap;
      margin: auto 0;
      line-height: 50px;
      padding: 0 64px 0 16px;
      max-width: 120px;
    }

    .logo {
      display: inline;
      background-image: url(/assets/images/logo.png);
      width: 95px;
      height: 25px;
      -webkit-background-size: 95px 25px;
      background-size: 95px 25px;
      margin: auto;
    }

    .container {
      width: auto;
      line-height: 25px;
      text-align: center;
    }

    .logoMasterloop {
      max-width: 100%;
      max-height: 100%;
      vertical-align: middle;
    }

    .search-bar {
      .search-form {
        background: rgba(255, 255, 255, 1);
        position: relative;
        margin-right: $gutter;
        display: block;
        margin-bottom: 0;
        max-width: 400px;

        @media (max-width: 767px) {
          margin-right: 0;
        }

        input {
          color: #000;
          font-family: "Muli", "Helvetica Neue", Helvetica, sans-serif;
          font-size: 1rem;
          height: 50px;
          padding: 10px;
          z-index: 2;
          cursor: text;
          text-indent: 30px;
          border: none;
          background: transparent;
          width: 100%;
          outline: 0;
          border-radius: 0;
        }

        .material-icons {
          position: absolute;
          top: 50%;
          left: 10px;
          margin-top: -12px;
          color: rgba(mat-color($foreground, base), 0.87);
        }
      }
    }

    .mat-icon-button {
      height: 35px;
      margin-left: 8px;
      width: 35px;
    }

    .mobile-menu-button {
      margin-left: 0;
      margin-right: 8px;
    }
  }
}

// Specific height for mobile devices in portrait mode.
@media ($mat-xsmall) and (orientation: portrait) {
  mat-toolbar {
    &.main-header {
      .branding {
        padding: 0 16px 0 16px;
      }
      .logo {
        width: 64px;
        height: 17px;
        -webkit-background-size: 64px 17px;
        background-size: 64px 17px;
      }
    }
  }
}

[dir="rtl"] {
  &.main-header {
    .branding {
      padding: 0 16px 0 64px;
    }

    .search-bar {
      .search-form {
        .material-icons {
          font-family: "Muli", "Helvetica Neue", Helvetica, sans-serif;
          left: auto;
          right: 10px;
        }
      }
    }
  }
}
