/* $Widget
 ------------------------------------------*/
.ngx-datatable.material {
  padding: 0;
  margin: ($gutter/3);
  @include mat-elevation(2);
  @include mat-elevation-transition;
  display: block;
  position: relative;
  border-radius: $border-radius-base;
  &.fullscreen {
    position: absolute !important;
    height: auto !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
  }
  &.striped {
    .datatable-row-odd {
      background: mat-color($background, "hover");
    }
  }

  &.single-selection,
  &.multi-selection {
    .datatable-body-row {
      &.active,
      &.active .datatable-row-group {
        @include _mat-toolbar-color($primary);
      }

      &.active:hover,
      &.active:hover .datatable-row-group {
        background-color: darken(mat-color($primary), 2%);
        color: #fff;
      }

      &.active:focus,
      &.active:focus .datatable-row-group {
        background-color: darken(mat-color($primary), 4%);
        color: #fff; //yellow !important;
      }
    }
  }

  &:not(.cell-selection) {
    .datatable-body-row {
      &:hover,
      &:hover .datatable-row-group {
        background: rgb(
          250,
          255,
          189
        ); //#ffff99 !important;// mat-color($background, 'hover');
      }

      &:focus,
      &:focus .datatable-row-group {
        background-color: darken(mat-color($background, "hover"), 2%);
      }
    }
  }

  &.cell-selection {
    .datatable-body-cell {
      &:hover,
      &:hover .datatable-row-group {
        background: mat-color($background, "hover");
      }

      &:focus,
      &:focus .datatable-row-group {
        background-color: darken(mat-color($background, "hover"), 2%);
      }

      &.active,
      &.active .datatable-row-group {
        @include _mat-toolbar-color($primary);
      }

      &.active:hover,
      &.active:hover .datatable-row-group {
        background-color: darken(mat-color($primary), 2%);
        color: #fff;
      }

      &.active:focus,
      &.active:focus .datatable-row-group {
        background-color: darken(mat-color($primary), 4%);
        color: #fff;
      }
    }
  }

  /**
   * Shared Styles
   */
  .empty-row {
    padding: 0.85rem 1.2rem;
  }

  .loading-row {
    padding: 0.85rem 1.2rem;
  }

  /**
   * Header Styles
   */
  .datatable-header {
    font-family: "Muli", "Helvetica Neue", Helvetica, sans-serif;
    border-bottom: 1px solid mat-color($foreground, divider);

    .datatable-header-cell {
      padding: 0.85rem 1.2rem;
      color: rgba(#000, .87);
      vertical-align: middle;

      &.longpress {
        color: #00e676;
      }

      &.dragging {
        .resize-handle {
          border-right: none;
        }
      }
    }

    .resize-handle {
      border-right: solid 1px mat-color($foreground, divider);
    }
  }

  /**
   * Body Styles
   */
  .datatable-body {
    .datatable-row-detail {
      background: #f5f5f5;
      padding: 10px;
    }

    .datatable-body-row {
      .datatable-body-cell {
        padding: 0.85rem 1.2rem;
        box-sizing: border-box;
        max-height: 100%;
        display: flex;
        flex-direction: row;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
        -webkit-box-pack: start;
        -webkit-box-align: center;

        -webkit-user-select: initial !important;
        -moz-user-select: initial !important;
        -ms-user-select: initial !important;
        -o-user-select: initial !important;
        user-select: initial !important;

        .datatable-body-cell-label {
          display: flex;
          width: 100%;
        }
      }
    }

    .progress-linear {
      .container {
        background-color: rgb(170, 209, 249);

        .bar {
          background-color: rgb(16, 108, 200);
        }
      }
    }
  }

  /**
   * Footer Styles
   */
  .datatable-footer {
    border-top: 1px solid mat-color($foreground, divider);
    padding: 0 15px;

    .datatable-pager {
      li {
        vertical-align: middle;

        a {
          padding: 0 5px;
        }

        &.disabled a {
          color: rgba(0, 0, 0, 0.26) !important;
          background-color: transparent !important;
        }

        &.active a {
          background-color: mat-color($primary);
          color: white;
          font-weight: $font-weight-base;
        }
      }
      //added for table pagination styling
      .pager {
        display: inline-flex;
      }

      //// Can be use to hide the pagination in mobile devices
      //  @media (max-width: 600px) {
      //   display: none;
      // }
    }
  }
}

.app-dark .ngx-datatable.material {
  background: mat-color($dark-background, card);
  color: mat-color($dark-foreground, base);
  &.striped {
    .datatable-row-odd {
      background: mat-color($dark-background, "hover");
    }
  }

  &.single-selection,
  &.multi-selection {
    .datatable-body-row {
      &.active,
      &.active .datatable-row-group {
        @include _mat-toolbar-color($dark-primary);
      }

      &.active:hover,
      &.active:hover .datatable-row-group {
        background-color: darken(mat-color($dark-primary), 2%);
      }

      &.active:focus,
      &.active:focus .datatable-row-group {
        background-color: darken(mat-color($dark-primary), 4%);
      }
    }
  }

  &:not(.cell-selection) {
    .datatable-body-row {
      &:hover,
      &:hover .datatable-row-group {
        background: mat-color($dark-background, "hover");
      }

      &:focus,
      &:focus .datatable-row-group {
        background-color: darken(mat-color($dark-background, "hover"), 2%);
      }
    }
  }

  &.cell-selection {
    .datatable-body-cell {
      &:hover,
      &:hover .datatable-row-group {
        background: mat-color($dark-background, "hover");
      }

      &:focus,
      &:focus .datatable-row-group {
        background-color: darken(mat-color($dark-background, "hover"), 2%);
      }

      &.active,
      &.active .datatable-row-group {
        @include _mat-toolbar-color($dark-primary);
      }

      &.active:hover,
      &.active:hover .datatable-row-group {
        background-color: darken(mat-color($dark-primary), 2%);
      }

      &.active:focus,
      &.active:focus .datatable-row-group {
        background-color: darken(mat-color($dark-primary), 4%);
      }
    }
  }

  .datatable-header .datatable-row-left,
  .datatable-body .datatable-row-left,
  .datatable-header .datatable-row-right,
  .datatable-body .datatable-row-right {
    background-color: rgba(73, 73, 73, 1);
  }

  /**
   * Header Styles
   */
  .datatable-header {
    border-bottom: 1px solid mat-color($dark-foreground, divider);

    .datatable-header-cell {
      color: rgba(#000, .87);
    }

    .resize-handle {
      border-right: solid 1px mat-color($dark-foreground, divider);
    }
  }

  /**
   * Footer Styles
   */
  .datatable-footer {
    border-top: 1px solid mat-color($dark-foreground, divider);
    padding: 0 15px;

    .datatable-pager {
      li {
        &.disabled a {
          color: rgba(255, 255, 255, 0.26) !important;
        }
        a {
          color: mat-color($dark-foreground, base);
        }
        &.active a {
          background-color: mat-color($dark-primary);
        }
      }
    }
  }
}

// .result-container {
//   .ngx-datatable.material {
//     .datatable-header {
//       .datatable-header-cell {
//         color: rgba(#000, .87);
//         font-size: 14px;
//       }
//     }

//     .datatable-footer {
//       .datatable-pager li {
//         a {
//           font-size: 16px;
//         }

//         &.active a {
//           background-color: mat-color($primary);
//         }
//       }

//       .page-count {
//         color: rgba(#000, .87);
//         font-size: 16px;
//       }
//     }
//   }
// }